import React, { useState } from 'react';
import styles from './pageForAddInfo.module.css';

const PageForAddInfo = () => {
    const [ticker, setTicker] = useState('');
    const [reportType, setReportType] = useState('Q');
    const [date, setDate] = useState('');
    const [inputs, setInputs] = useState({
        revenue: {
            totalRevenue: 0,
            costOfRevenue: 0,
            grossProfit: 0
        },
        operation_income_and_expenses: {
            operatingIncome: 0,
            totalOperatingExpenses: 0,
            researchDevelopment: 0,
            sellingGeneralAdministrative: 0
        },
        earnings_from_cont_operations: {
            interestExpense: 0,
            interestIncome: 0
        },
        net_income: {
            netIncome: 0
        },
        supplemental_income: {
            ebitda: 0
        },
        long_term_assets: {
            totalAssets: 0
        },
        supplemental_balance: {
            commonStockSharesOutstanding: 0,
            totalDebt: 0,
            netDebt: 0,
            ev: 0
        },
        cash_flow_from_opetaions: {
            totalCashFromOperatingActivities: 0,
            freeCashFlow: 0
        },
        key_stats: {
            eps: 0,
            pe: 0,
            payout: 0,
            revenuepershare: 0,
            pricetobookvalue: 0,
            netdebttoebitda: 0,
            debttoequity: 0,
            roa: 0,
            roe: 0
        }
    });

    const categoryLabels = {
        revenue: 'Доходы',
        operation_income_and_expenses: 'Операционные доходы и расходы',
        earnings_from_cont_operations: 'Доходы от непрерывных операций',
        net_income: 'Чистый доход',
        supplemental_income: 'Дополнительно',
        long_term_assets: 'Долгосрочные активы',
        supplemental_balance: 'Дополнительно (Бухгалтерский баланс)',
        cash_flow_from_opetaions: 'Денежный поток',
        key_stats: 'Ключевые мультипликаторы'
    };

    const fieldLabels = {
        revenue: {
            totalRevenue: 'Итоговая выручка',
            costOfRevenue: 'Себестоимость',
            grossProfit: 'Валовая прибыль'
        },
        operation_income_and_expenses: {
            operatingIncome: 'Операционная прибыль',
            totalOperatingExpenses: 'Общие операционные расходы',
            researchDevelopment: 'Расходы на исследования',
            sellingGeneralAdministrative: 'Административные расходы'
        },
        earnings_from_cont_operations: {
            interestExpense: 'Расходы на обслуживание долга',
            interestIncome: 'Доход от процентов'
        },
        net_income: {
            netIncome: 'Чистая прибыль'
        },
        supplemental_income: {
            ebitda: 'EBITDA'
        },
        long_term_assets: {
            totalAssets: 'Долгосрочные активы'
        },
        supplemental_balance: {
            commonStockSharesOutstanding: 'Количество обыкновенных акций',
            totalDebt: 'Общий долг',
            netDebt: 'Чистый долг',
            ev: 'EV'
        },
        cash_flow_from_opetaions: {
            totalCashFromOperatingActivities: 'Чистый денежный поток',
            freeCashFlow: 'Свободный денежный поток'
        },
        key_stats: {
            eps: 'EPS',
            pe: 'PE',
            payout: 'Payout',
            revenuepershare: 'Выручка на акцию',
            pricetobookvalue: 'Price to Book Value',
            netdebttoebitda: 'Net Debt to EBITDA',
            debttoequity: 'Debt to Equity',
            roa: 'ROA',
            roe: 'ROE'
        }
    };

    const handleChange = (category, field, value) => {
        setInputs(prevInputs => ({
            ...prevInputs,
            [category]: {
                ...prevInputs[category],
                [field]: value !== '' ? parseFloat(value) : '-'
            }
        }));
    };

    // const generateJson = () => {
    //     const formattedData = Object.keys(inputs).flatMap(category =>
    //         Object.keys(inputs[category]).map(key => ({
    //             parameter: key,
    //             type: reportType,
    //             date: `${date}T00:00:00`,
    //             value: inputs[category][key]
    //         }))
    //     );
    //
    //     return formattedData;
    // };

    const submitForm = async (data) => {
        const token = localStorage.getItem('token');
        const headers = {
            'Content-Type': 'application/json',
        };
        if (!!token) {
            headers.Authorization = `Token ${token}`;
        }
        try {
            const response = await fetch('/api/financial-data/update-data/', {
                method: 'PATCH',
               headers,
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Success:', result);
            } else {
                console.error('Error:', response.statusText);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSubmit = () => {
        const jsonData = {
            ticker,
            report_type: reportType,
            date,
            inputs
        };
       submitForm(jsonData);
    };

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <h2>Форма отправки данных</h2>

                <div className={styles.flexWrap}>
                    <div>
                        <label>Выбор компании:</label>
                        <input type="text" value={ticker} onChange={(e) => setTicker(e.target.value)} />
                    </div>

                    <div>
                        <label>Выбор типа отчета:</label>
                        <select value={reportType} onChange={(e) => setReportType(e.target.value)}>
                            <option value="Y">Годовой</option>
                            <option value="Q">Квартальный</option>
                        </select>
                    </div>

                    <div>
                        <label>Дата:</label>
                        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
                    </div>

                    {Object.keys(inputs).map(category => (
                        <div key={category}>
                            <h3>{categoryLabels[category]}</h3>
                            {Object.keys(inputs[category]).map(field => (
                                <div key={field}>
                                    <label>{fieldLabels[category][field]}:</label>
                                    <input
                                        type="number"
                                        step="any"
                                        value={inputs[category][field]}
                                        onChange={(e) => handleChange(category, field, e.target.value)}
                                    />
                                </div>
                            ))}
                        </div>
                    ))}

                    <button onClick={handleSubmit}>Отправить</button>
                </div>
            </div>
        </div>
    );
};

export default PageForAddInfo;
