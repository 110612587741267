import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getInformationScreenerPage} from "../../../services/reducers/screener-slice";
import ScreenerPageHeader from "./ui/screener-page-header/screener-page-header";
import ScreenerNavigate from "./ui/screener-navigate/screener-navigate";
import ScreenerTabInformation from "./ui/screener-tab-information/screener-tab-information";
import ScreenerTabFinance from "./ui/screener-tab-finance/screener-tab-finance";
import ScreenerTabAnalysis from "./ui/screener-tab-analysis/screener-tab-analysis";
import ScreenerTabCalendar from "./ui/screener-tab-calendar/screener-tab-calendar";
import ScreenerTabDocs from "./ui/screener-tab-docs/screener-tab-docs";
import Preloader from "../../preloader/preloader";
import {ScreenerTabPredictions} from "./ui/screener-tab-predictions/screener-tab-predictions";
import styles from './screener.module.css'

const ScreenerPage = () => {
    const [activeTab, setActiveTab] = useState('Информация');
    const {loading, information} = useSelector((store) => store.screener);
    const dispatch = useDispatch();
    const params = useParams();

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Информация':
                return <ScreenerTabInformation />;
            case 'Финансовые показатели':
                return <ScreenerTabFinance information={information}/>;
            case 'Прогнозы':
                return <ScreenerTabPredictions information={information}/>;
            case 'Анализ':
                return <ScreenerTabAnalysis information={information}/>;
            case 'Календарь':
                return <ScreenerTabCalendar />;
            case 'Документы':
                return <ScreenerTabDocs />;
            default:
                return <ScreenerTabInformation />;
        }
    };

    useEffect(() => {
        dispatch(getInformationScreenerPage(params?.slug));
    }, [params.slug]);
///
    return (
        loading ? (
            <>
                <section className={styles.skriner}>
                    <div className={styles.preloader}>
                        <Preloader/>
                    </div>
                </section>
            </>
        ) : (
            <>
                    <ScreenerPageHeader/>
                    <section className={styles.skriner}>
                        <div className={styles.container}>
                            <ScreenerNavigate setActiveTab={setActiveTab}/>
                            {renderTabContent()}
                        </div>
                    </section>
                </>
            )

    );
};

export default ScreenerPage;
