import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import { useWebinar } from "../../../services/context/WebinarContext"
import WebinarBox from "./WebinarBox/WebinarBox.jsx/webinarbox"
import styles from "./webinars.module.css"
import {BreadcrumbNavigation} from "../../breadcrumb-navigation/breadcrumbsNavigation";

function Webinars() {
  const val = useWebinar()

  const [link, setLink] = useState(1);

    const bntArr = [
        {id: 1, name: 'Vimeo'},
        {id: 2, name: 'Youtube'},
    ]

  useEffect(() => {
    val.getWebinarDataApi()
  }, [])

  const webinarsNames = () => Object.keys(val.webinarsData.webinars)

  let youtubeLink = val.tabName && val.webinarsData.webinars[val.tabName].filter((el =>el?.link?.includes('youtube')));
  let vimeoLink = val.tabName && val.webinarsData.webinars[val.tabName].filter((el =>el?.link?.includes('vimeo')));

  return (
    <>
      <section className={styles.section}>
        <BreadcrumbNavigation className={styles.linkWrapper} links={[{ label: "Профиль", url: "/profile" }]} forBg={"light"} />

        <div className={styles.containerWrapper}>
          <div className={styles.container}>
            <div className={styles.mainTitle}>Вебинары</div>
            <div className={styles.scrollWrapper}>
            <div className={styles.tabsWrapper}>
              {val.webinarsData &&
                webinarsNames().map((el, i) => {
                  return (
                      <div
                        key={el + i}
                        className={ val.tabNumber === i ? styles.tabWrapActive : styles.tabWrap }
                        onClick={() => {
                          val.setTabNumber(i)
                          val.setTabName(el)
                        }}
                      >
                        <div className={styles.tabTitle}>{el}</div>
                      </div>
                  )
                })}
            </div>
            </div>

            {(vimeoLink || vimeoLink) && <div className={styles.btnContainer} >
              {
                bntArr.map((item, index) => {
                    const activeBtn = item.id === link ? styles.activeBtn : '';
                  return (
                      <button
                          className={`${styles.btnNav} ${activeBtn}`}
                          key={item.id + item.name + index}
                          onClick={() => setLink(item.id)}>{item.name}</button>
                  )
                })
              }
            </div>}
                <div className={styles.webinarsWrapper}>
                   <WebinarBox data={link === 1 ? vimeoLink : youtubeLink}/>
                </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default Webinars
