import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useParams} from 'react-router-dom';
import {useEffect, useMemo} from 'react';
import {setIsLoading} from '../../../services/reducers/preloader-slice';
import Preloader from '../../preloader/preloader';
import SEO from '../../../utils/SEO';
import BreadcrumbList from './seo-script';
import {useEducationContext} from '../../../services/context/EducationContext';
import styles from './lessons-page.module.css';
import CanMightBeInteresting from "../../can-might-be-interesting/can-might-be-interesting";
import { BreadcrumbNavigation } from '../../breadcrumb-navigation/breadcrumbsNavigation';

function LessonsPage() {
    const {slug} = useParams()
    const value = useEducationContext()
    const cardTheme = value.lessonsData
    const lessons = cardTheme.lessons
    const isLoading = useSelector((store) => store.preloader.isLoading)
    const dispatch = useDispatch()
    const subscriptionUser = JSON.parse(localStorage.getItem("subscription"))
    const recommendedPosts = useSelector((store) => store.recommendedPosts.recommendedPosts);
    // const cardTheme = useSelector((store) => store.cards.cardTheme)
    // const lessons = useSelector((store) => store.cards.lessonsCards)

    const checkSubscribe = (cardSubscribe) => {
        if (subscriptionUser) {
            const val = subscriptionUser.some((el) => {
                return el.subscription.includes("Unity")
                // return el.subscription === cardSubscribe
            })
            return val
        }
    }

    const numberOfHiddenPost = useMemo(() => {
        return !checkSubscribe(cardTheme.subscription) && cardTheme.subscription !== null ? 3 : null;
    }, [cardTheme]);

    const token = localStorage.getItem("token")
    const isAuthenticated = !!token

    useEffect(() => {
        dispatch(setIsLoading(true))
        // value.getThemesCardDataApi(slug)
        // dispatch(setThemesSlug(slug))
        localStorage.setItem("themeActive", slug)
        // dispatch(getLessonsCard(slug))
        value.getLessonsDataApi(slug)
        // value.getLessonCardDataApi("lichnie-finansi")
    }, [slug])

    return (
        <section>

            {isLoading ? (
                <div className={styles.preloader}>
                    <Preloader/>
                </div>
            ) : (
                <div>
                    <SEO
                        title={cardTheme.title_seo ? cardTheme.title_seo : cardTheme.name}
                        description={cardTheme.description}
                        ogTitle={cardTheme.title_seo ? cardTheme.title_seo : cardTheme.name}
                        ogDescription={cardTheme.description}
                        script={BreadcrumbList}
                    />
                    <div className={styles.section}>
                    <BreadcrumbNavigation className={styles.linkBox} links={[{label: "Главная", url: "/"}, {label: "Обучение", url: "/education"}]} forBg="light"/>

                        <div className={styles.container}>
                            <div className={styles.textBox}>
                                {cardTheme.name === 'Обучение личным финансам' || cardTheme.name === 'Обучение облигациям' ||
                                cardTheme.name === 'Составляем инвестиционный портфель' ?
                                    <h1 className={styles.title}>{cardTheme.name}</h1> :
                                    <h2 className={styles.title}>{cardTheme.name}</h2>}
                                <div className={styles.for}>
                                    {cardTheme.subscription ? (
                                        <span
                                            className={styles.subscription}
                                        >{`С подпиской Unity`}</span>
                                    ) : null}
                                    {/*
                    <span className={styles.subscription} >{`С подпиской ${cardTheme.subscription}`}</span>
                    */}
                                    <span className={styles.lvl}>{cardTheme.level}</span>
                                </div>

                                <p className={styles.text}>{cardTheme.description}</p>
                            </div>
                        </div>
                    </div>

                    <div className={styles.box}>
                        <h4 className={styles.h4}>Содержание</h4>
                        <div className={styles.lessonsBox}>
                            <div className={styles.lessons}>
                                {lessons === undefined
                                    ? null
                                    : lessons.map((element, index) => {
                                        const check =
                                            (!isAuthenticated && element.subscription !== null) ||
                                            (isAuthenticated &&
                                                !checkSubscribe(element.subscription) &&
                                                element.subscription !== null)
                                        return check ? (
                                            <div className={styles.lesson} key={index}>
                                                <h2 className={styles.count}>{element.priority}</h2>
                                                <div className={styles.textContainer}>
                                                    <h3 className={styles.h3}>{element.name}</h3>

                                                    <div className={styles.timeBox}>
                                                        <div className={styles.time}></div>
                                                        <span className={styles.timeText}>
                                {element.reading_time}
                              </span>
                                                    </div>
                                                </div>
                                                {check ? <div className={styles.block}></div> : null}
                                            </div>
                                        ) : (
                                            <NavLink
                                                to={`/lessons/${element.slug}`}
                                                className={styles.linkToLesson}
                                                key={index}
                                            >
                                                <div className={styles.lesson}>
                                                    <h2 className={styles.count}>{element.priority}</h2>
                                                    <div className={styles.textContainer}>
                                                        <h3 className={styles.h3}>{element.name}</h3>

                                                        <div className={styles.timeBox}>
                                                            <div className={styles.time}></div>
                                                            <span className={styles.timeText}>
                                  {element.reading_time}
                                </span>
                                                        </div>
                                                    </div>
                                                    {check ? (
                                                        <div className={styles.block}></div>
                                                    ) : null}
                                                </div>
                                            </NavLink>
                                        )
                                    })}
                            </div>

                            <div className={styles.rightBlock}>
                            {!isAuthenticated &&
                            !checkSubscribe(cardTheme.subscription) &&
                            cardTheme?.subscription !== null &&
                            cardTheme?.subscription !== undefined ? (
                                <div className={styles.subBox}>
                                    <div className={styles.logo}></div>
                                    <span className={styles.textSub}>
                    {`Оформите подписку Unity, для получения доступа к материалам`}
                  </span>
                                    <NavLink
                                        to={"/profile"}
                                        // target={"_blank"}
                                        className={styles.button}
                                    >
                                        Оформить подписку
                                    </NavLink>
                                </div>
                            ) : null}

                            <CanMightBeInteresting
                                posts={recommendedPosts}
                                numberOfHiddenPost={numberOfHiddenPost}
                            />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    )
}

export default LessonsPage
