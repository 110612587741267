import { useMemo } from "react"
import { NavLink } from "react-router-dom"
import { usePageInfoContext } from "../../../services/context/PageInfoContext"
import styles from "./page-info.module.css"

function PageInfo() {
  const valueContext = usePageInfoContext()

  useMemo(() => {
    valueContext.infoDataFromApi()
  }, [])

  return (
    <>
      <section className={styles.section}>
        <div className={styles.navigationBox}>
          <NavLink to={"/"} className={styles.link}>
            <div className={styles.arrow}></div>
            Главная
          </NavLink>
        </div>
        <div className={styles.mainContentWrapper}>
        <div className={styles.mainTitle}>
          Информационные страницы
        </div>
          <div className={styles.mainContent}>
            {valueContext.firstData &&
              valueContext.firstData.results.map((el, i) => {
                return (
                  <NavLink to={el.slug} className={styles.urlLinks} key={i}>
                    {el.name}
                  </NavLink>
                )
              })}
          </div>
        </div>
      </section>
    </>
  )
}

export default PageInfo
