import {useEffect, useState} from "react";
import BlogNavigate from "./ui/Navigate/BlogNavigate";
import AllCategories from "./ui/AllCategories/AllCategories";
import {blogActions} from "../../../services/reducers/blog-slice";
import {useDispatch} from "react-redux";
import { BreadcrumbNavigation } from "../../breadcrumb-navigation/breadcrumbsNavigation";
import styles from './blog-page.module.css'


const BlogPage = () => {
    const [marks, setMarks] = useState();
    const dispatch = useDispatch();

    //очищаем данные стора
    useEffect(() => {
        return () => {
            dispatch(blogActions.clearState());
            dispatch(blogActions.clearNavigateData());
        }
    }, [dispatch])

    return (
        <>
            <section className={styles.blog}>
                <div className={styles.blogWrapper}>
                    <BreadcrumbNavigation links={[{label: "Главная", url: "/"}]} forBg="light"/>
                    <h1 className={styles.mainTitle}>Полезно инвестору</h1>
                    <BlogNavigate marks={marks} setMarks={setMarks} />
                    <AllCategories marks={marks} />
                </div>
            </section>
        </>
    );
};

export default BlogPage;
