import {indicatorValue, transformKeyStatsValues} from "../../utilsForFinanceTab";
import styles from './custom-tooltip.module.css'

export const CustomTooltip = ({active, payload, label, ticker, selectedCompany}) => {

    if (active && payload && payload.length) {
        return (
            <div className={styles.tooltip}>
                <p className={styles.label}>{label}</p>
                {payload.map((data, index) => {
                    return <div key={index} className={styles.indicatorContainer}>
                        <span className={styles.indicator}>
                        <span className={styles.circle} style={{backgroundColor: data.color}}></span>
                            {`${data.name.includes('Compare') ? selectedCompany : ticker}:`} {indicatorValue(data.name)}
                         </span>
                        <span className={`${data.value < 0 && styles.negativeValue}`}>
                            {transformKeyStatsValues(data.value, data.dataKey)}
                        </span>
                    </div>
                }

                )}
            </div>
        );
    }

    return null;
};