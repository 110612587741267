import styles from './footer-main.module.css';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import InverstEraLogo from '../../images/INVESTERA.svg';
import Logo from '../../images/logo-logo.svg';
import Telegram from '../../images/footer-logo-youtube.svg';
import YouTube from '../../images/tg-logo-footer.svg';
import { useMediaQuery } from 'react-responsive';

function FooterMain() {
  const isAuthChecked = useSelector((store) => store.user.isAuthChecked);
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className={styles.footer}>
      {!isMobile ? (
        <div className={styles.wrapper}>
          <div className={styles.info}>
            <NavLink to="/" className={styles.footerLogo}>
              <img src={Logo} alt="logo" />
              <img src={InverstEraLogo} alt="logo" />
            </NavLink>

            <div className={`${styles.desc} ${styles.desc2}`}>
              Обращаем внимание: все материалы, <br /> представленные на сайте, не являются <br />{' '}
              инвестиционной рекомендацией.
            </div>
            <div className={`${styles.desc} ${styles.desc3}`}>
              ©ИП Артём Николаев, 2024. Все права защищены.{' '}
            </div>
          </div>
          <div className={styles.navigate}>
            <div className={styles.navigateFirst}>
              <NavLink className={styles.navigateLink} to="/" onClick={scrollToTop}>
                Главная
              </NavLink>
              <NavLink className={styles.navigateLink} to={'/analytics-and-news'}>
                Аналитика и новости
              </NavLink>
              <NavLink className={styles.navigateLink} to={'/market-map'}>
                Карта рынка
              </NavLink>
            </div>
            <div className={styles.navigateSecond}>
              <NavLink className={styles.navigateLink} to={'/education'}>
                Обучение
              </NavLink>
              <NavLink className={styles.navigateLink} to={'/webinars'}>
                Вебинары
              </NavLink>
              <NavLink className={styles.navigateLink} to={'/about-us'}>
                О компании
              </NavLink>
            </div>
          </div>
          <div className={styles.contacts}>
            <div className={styles.contactsLink}>
              {/* <a href="tel:79000000000" className={styles.phone}>
                +7 (900) 000-00-00
              </a>
              <div className={styles.subTitle}>Для звонков по России</div> */}
              <a href="https://t.me/+Avs2bsQ5mQk4N2My" className={styles.youtube} target={'_blank'} rel="noopener noreferrer">
                <img src={Telegram} alt="Telegram"/>
              </a>
              <a href="https://www.youtube.com/@investera3816" className={styles.youtube} target={'_blank'} rel="noopener noreferrer">

                <img src={YouTube} alt="YouTube"/>
              </a>
            </div>

            <div className={styles.agreements}>
              <NavLink
                className={styles.infoLink}
                to={'/info/politika-konfidentsialnosti'}>
                Политика конфиденциальности
              </NavLink>
              <NavLink
                className={styles.infoLink}
                to={
                  '/info/dogovor-publichnoj-oferti-na-okazanie-informatsionno-konsultatsionnikh-uslug'
                }>
                Пользовательское соглашение
              </NavLink>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <NavLink to="/" className={styles.footerLogo}>
            <img src={Logo} alt="logo" />
            <img src={InverstEraLogo} alt="logo" />
          </NavLink>

          <div className={styles.contactsLink}>
            <a href="https://t.me/ie_contact" className={styles.youtube} target={'_blank'} rel="noopener noreferrer">
              <img src={Telegram} alt="Telegram"/>

            </a>
            <a href="https://www.youtube.com/@investera3816" className={styles.youtube} target={'_blank'} rel="noopener noreferrer">
              <img src={YouTube} alt="YouTube"/>
            </a>
          </div>
          <div className={styles.navigate}>
            <NavLink className={styles.navigateLink} to="/" onClick={scrollToTop}>
              Главная
            </NavLink>
            <NavLink className={styles.navigateLink} to={'/analytics-and-news'}>
              Аналитика и новости
            </NavLink>
            <NavLink className={styles.navigateLink} to={'/market-map'}>
              Карта рынка
            </NavLink>
            <NavLink className={styles.navigateLink} to={'/education'}>
              Обучение
            </NavLink>
            <NavLink className={styles.navigateLink} to={'/webinars'}>
              Вебинары
            </NavLink>
            <NavLink className={styles.navigateLink} to={'/about-us'}>
              О компании
            </NavLink>
          </div>

          <div className={`${styles.desc} ${styles.desc2}`}>
            Обращаем внимание: все материалы, представленные на сайте, не являются инвестиционной
            рекомендацией.
          </div>
          <div className={styles.agreements}>
            <NavLink
              className={styles.infoLink}
              to={'/info/politika-konfidentsialnosti'}>
              Политика конфиденциальности
            </NavLink>
            <NavLink
              className={styles.infoLink}
              to={
                '/info/dogovor-publichnoj-oferti-na-okazanie-informatsionno-konsultatsionnikh-uslug'
              }>
              Пользовательское соглашение
            </NavLink>
          </div>
          <div className={`${styles.desc} ${styles.desc3}`}>
            ©ИП Артём Николаев, 2024. <br /> Все права защищены.
          </div>
        </div>
      )}
    </footer>
  );
}

export default FooterMain;
