import {useEffect, useMemo, useRef} from "react"
import {NavLink, useParams} from "react-router-dom"
import {Helmet} from "react-helmet"
import {usePageInfoContext} from "../../../../services/context/PageInfoContext"
import Preloader from "../../../preloader/preloader";
import styles from "./page-url-info.module.css"

const PageInfoUrl = () => {
    const valueContext = usePageInfoContext()
    const {slug} = useParams()

    useMemo(() => {
        valueContext.pageFromSlugUrl(slug)
    }, [slug])

    const card = valueContext.urlPageData

    const containerRef = useRef(null);

    useEffect(() => {
        // Проверяем наличие figure с table
        const container = containerRef.current;
        if (container) {
            const figures = container.querySelectorAll('figure.table');
            figures.forEach(figure => {
                if (figure.querySelector('table')) {
                    // Применяем стиль overflow: scroll
                    figure.style.overflow = 'scroll';
                }
            });
        }
    }, [card.text]);

    return (
        <>
            <section className={styles.section}>
                <Helmet>
                    <title>{card.name}</title>
                    <meta name="description" content={"информационная карточка"}/>
                </Helmet>
                {card.slug !== slug ? (
                    <div className={styles.preloader}>
                        <Preloader />
                    </div>
                    ) : (card &&
                    <div className={styles.aboutCard}>
                        <div className={styles.navigationBox}>
                            <NavLink to={"/"} className={styles.link}>
                                <div className={styles.arrow}></div>
                                Главная
                            </NavLink>

                            <NavLink to={"/info"} className={styles.link}>
                                <div className={styles.arrow}></div>
                                Страницы
                            </NavLink>
                        </div>
                        <div className={styles.headerTitleWrapper}
                             style={{maxWidth: '1440px', margin: "auto", wordWrap: "break-word"}}>
                            <span className={styles.pubDate}>{card.pub_date_formated}</span>
                            <h3 className={styles.title}>{card.name}</h3>
                            <span className={styles.desc}>{card.description}</span>
                            <div
                                ref={containerRef}
                                className={styles.container}
                                style={{maxWidth: '1440px', margin: "auto", wordWrap: "break-word"}}
                                dangerouslySetInnerHTML={{__html: card.text}}
                            >
                            </div>
                        </div>
                    </div>)
                }
            </section>
        </>
    )
}

export default PageInfoUrl