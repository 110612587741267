import styles from './main-profile.module.css';
// import editProfileButton from "../../images/edit-profile-button.svg"
import MailIconProfile from '../../images/mail-profile-data-icon.svg';
import PhoneIconProfile from '../../images/phone-profile-data-icon.svg';
import logOutIcon from '../../images/log-out-door-profile-icon.svg';
import BoxSubscriptionOwn from './subscription-box/sub-box-profile-own';
import BoxSubscriptionForPay from './subscription-box/box-profile-for-buy';
import WindowPaySub from './window-pay-sub/window-pay-sub';
import CancelModalFirst from './popup-sections/cancel-sub/cancel-fist';
import CancelModalSecond from './popup-sections/cancel-sub/cancel-second';
import CancelModalError from './popup-sections/cancel-sub/cancel-error';
import { useEffect, useLayoutEffect, useRef, useState, useMemo, useCallback } from 'react';
import { NavLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Modal from '../modal/modal';
import { useMediaQuery } from 'react-responsive';
import { setAuthChecked, setUser } from '../../services/reducers/user-slice';
import { ViewModalSubs } from './no-auth-profile/no-auth-profile';
import { isClickEnter, isOpenModal, isUserDelete } from '../../services/reducers/modal-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useProfile } from '../../services/context/ProfileContext';
import SubscriptionSuccess from './popup-sections/pay-sub/pay-success';
import SubscriptionWarning from './popup-sections/pay-sub/pay-warning';
import ExteriorNotice from './popup-sections/pay-sub/exterior-notice';
import { useActiveButtonCountryTagChoice } from '../../services/context/CountryFilterContext';
import {BreadcrumbNavigation} from "../breadcrumb-navigation/breadcrumbsNavigation";

const AppMainProfile = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 760px)' });
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contextValue = useProfile();
  const value = useActiveButtonCountryTagChoice();
  const [showWarningCancelModal, setShowWarningCancelModal] = useState(false);
  const [showExteriorNotice, setShowExteriorNotice] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showNoSubsInfo, setShowNoSubsInfo] = useState(false);
  const [subSelect, setSubSelect] = useState('');
  const [subNameSelect, setSubNameSelect] = useState('');

  useEffect(() => {
    if (showModal || showNoSubsInfo) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [showModal, showNoSubsInfo]);

  // ======== Выброс пользователя без токена
  const token = localStorage.getItem('token');
  useEffect(() => {

    if (token === null) {
      navigate('/authorization');
      dispatch(isOpenModal(true));
    }
  }, []);

  useEffect(() => {
    if (token) {
      contextValue.userProfileData();
      contextValue.userSubscriptionData();
    }
  }, []);

  const come = useCallback(() => {
    if (!!searchParams.get('payment')) {
      contextValue.setPopUpActive(true);
    }
    if(searchParams.get('payment') === 'success') {
      if (!!window.ym) {
        window.ym(96742836, 'reachGoal', 'oplata')
      }
    }

    return searchParams.get('payment');
  }, [searchParams.get('payment')]);

  const logout = useCallback(() => {
    value.setPopUpExitVision((prev) => !prev);
  }, []);

  return (
    <>
      {(contextValue.popUpActive &&
        come() === 'success' &&
        contextValue.userSubInfo.length !== 0 && <SubscriptionSuccess />) ||
        (come() === 'failed' && <SubscriptionWarning />)}

      {!showWarningCancelModal ||
        (contextValue.cancelSubErrorMessage && (
          <CancelModalError setShowWarningCancelModal={setShowWarningCancelModal} />
        )) ||
        (contextValue.cancelSubscription && (
          <CancelModalSecond
            setShowWarningCancelModal={setShowWarningCancelModal}
            showWarningCancelModal={showWarningCancelModal}
          />
        )) || (
          <CancelModalFirst
            setShowWarningCancelModal={setShowWarningCancelModal}
            showWarningCancelModal={showWarningCancelModal}
          />
        )}

      {showModal && (
        <WindowPaySub
          setShowExteriorNotice={setShowExteriorNotice}
          subNameSelect={subNameSelect}
          subSelect={subSelect}
          setShowModal={setShowModal}
        />
      )}
      {showExteriorNotice && <ExteriorNotice setShowExteriorNotice={setShowExteriorNotice} />}
      {showNoSubsInfo && (
        <ViewModalSubs activeName={subNameSelect} setOpenModalSubs={setShowNoSubsInfo} />
      )}

      <div className={styles.section}>

        {/*==============   topContainer   ===================================================*/}
        <div className={styles.topContainerWrapper}>
          <div className={styles.userDataContainerWrapper}>
            <BreadcrumbNavigation links={[{ label: "Главная", url: "/" }]} forBg={"light"} />
            <div className={styles.userName}>{contextValue.userData.username}</div>
            <div className={styles.userMinorDataWrapper}>
              <div className={styles.userMinorData}>
                <img src={MailIconProfile} className={styles.iconProfile} />
                {contextValue.userData.email}
              </div>
              <div className={styles.userMinorData}>
                {!!contextValue.userData.length && (
                  <img src={PhoneIconProfile} className={styles.iconProfile} />
                )}
                {contextValue.userData.phone}
              </div>
            </div>
          </div>
          {/*
          <div className={styles.editProfileButtonWrapper}>
            <img src={editProfileButton} className={styles.editProfileButton} />
            <div className={styles.editButtonTxt}>Редактировать профиль</div>
          </div>
          */}
        </div>
        {/*^^^^^^^^^^^^^^^^^^   topContainer   ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^*/}

        {/*==============   mainSectionContainer   ===================================================*/}
        <div className={styles.mainProfileContainerWrapper}>
          {!isMobile && (
            <div className={styles.sideBarWrapper}>
              <div className={styles.sideBar}>
                <div className={styles.sideBarPoint}>
                  <div className={styles.greenDot} />
                  Подписки
                </div>
                <NavLink to={'/webinars'} className={styles.sideBarPoint}>
                  Вебинары
                </NavLink>
                <div style={{ color: '#b0b1b3' }} className={styles.sideBarPoint}>
                  Настройки
                </div>
                <div className={styles.sideBarPoint} style={{ cursor: 'pointer' }} onClick={logout}>
                  Выход
                  <img src={logOutIcon} className={styles.logOutIcon} />
                </div>
              </div>
            </div>
          )}

          {isMobile && (
            <div className={styles.mobileMenuWrapper}>
              <div className={styles.scrollWrapper}>
                <div className={styles.mobileMenuActive}>
                  <div className={styles.menuTitle}>Подписки</div>
                </div>
                <NavLink to={'/webinars'} className={styles.mobileMenu}>
                  <div className={styles.menuTitle}>Вебинары</div>
                </NavLink>
                <div className={styles.mobileMenu}>
                  <div style={{ color: '#b0b1b3' }} className={styles.menuTitle}>
                    Настройки
                  </div>
                </div>
                <div className={styles.mobileMenu} onClick={logout}>
                  <div className={styles.menuTitle}>Выход</div>
                </div>
              </div>
            </div>
          )}

          {contextValue.userSubInfo && (
            <div className={styles.contentWrapper}>

              {contextValue.userSubInfo.length > 0 &&
                contextValue.userSubInfo.filter((el) => el.is_active).length !== 0 && (
                  <div className={styles.containerContentWrapper}>
                    <div className={styles.headerContent}>Мои подписки</div>
                    <div className={styles.componentsWrapper}>
                      {contextValue.userSubInfo
                        .filter((el) => el.is_active)
                        .map((el, i) => {
                          return (
                            <BoxSubscriptionOwn
                              key={i}
                              subInfo={el}
                              setShowWarningCancelModal={setShowWarningCancelModal}
                              setSubSelect={setSubSelect}
                              setShowModal={setShowModal}
                              setSubNameSelect={setSubNameSelect}
                            />
                          );
                        })}
                    </div>
                  </div>
                )}

              {contextValue.userSubInfo.length > 0 &&
                contextValue.userSubInfo.filter((el) => !el.is_active).length !== 0 && (
                  <div className={styles.containerContentWrapper}>
                    <div className={styles.headerContent}>Все подписки</div>
                    <div className={styles.componentsWrapper}>
                      {contextValue.userSubInfo
                        .filter((el) => !el.is_active)
                        .map((el, i) => {
                          return (
                            <BoxSubscriptionForPay
                              setShowNoSubsInfo={setShowNoSubsInfo}
                              key={i}
                              subInfo={el}
                              setSubSelect={setSubSelect}
                              setShowModal={setShowModal}
                              setSubNameSelect={setSubNameSelect}
                            />
                          );
                        })}
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AppMainProfile;
