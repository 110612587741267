import YouTubePlayer from "../youtube-video/youtube-video";
import VimeoPlayer from "../vimeo-video/vimeo-video";
import styles from './webinarbox.module.css';

function WebinarBox({data}) {
    const partsVimeo = data && data?.map((item) => item?.link?.split('/').slice(3, 4));
    const partsYoutube = data && data?.map((item) => item?.link?.substring(item?.link.lastIndexOf('/') + 1));

    if (!data) {
        return <div className={styles.noData}>Для доступа к вебинарам, оформите необходимую <a
            href="/profile">подписку</a>
        </div>;
    }
    if (data.length === 0) {
        return <div className={styles.noData}>В данный момент видео отсутствуют</div>;
    }

    return (
        data &&
        data?.map((item, index) => {

            return item?.link?.includes('youtube') ? (
                <div key={index} className={styles.wrapper}>
                    <YouTubePlayer videoId={partsYoutube[index]} title={item.name} description={item?.description}/>
                </div>
            ) : (
                <div key={index} className={styles.wrapper}>
                    <VimeoPlayer videoId={partsVimeo[index]} title={item?.name} description={item?.description}/>
                </div>
            );
        })
    );
}

export default WebinarBox;
