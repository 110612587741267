import MainSectionInfo from '../main-section-info/main-section-info';
import MainSectionAnalytics from '../main-section-analytics/main-section-analytics';
import MainSectionProduct from '../main-section-product/main-section-product';
import MainSectionQuestions from '../main-section-questions/main-section-questions';
import MainSectionEcosystem from '../main-section-ecosystem/main-section-ecosystem';
import SEO from '../../utils/SEO';
import SeoScriptMain from './seo-script';
import TenDaysPromocode from '../promocode-10days/promocode-tenDays';
import styles from './app-main.module.css';
// import {Tariffs} from "../tariffs/tariffs";
// import MainSectionNews from '../main-section-news/main-section-news';
// import MainSectionLessons from '../main-section-lessons/main-section-lessons';

function AppMain() {
  return (
    <main>
      <div className={styles.info}>
        <SEO
          title="Invest Era: фундаментальная аналитика, инвест идеи, обучение"
          description="Повышаем доходность на фондовом рынке, используя фундаментальный анализ. Разборы компаний, инвест идеи, обучение."
          ogTitle="Invest Era: фундаментальная аналитика, инвест идеи, обучение"
          ogDescription="Повышаем доходность на фондовом рынке, используя фундаментальный анализ. Разборы компаний, инвест идеи, обучение."
          script={SeoScriptMain}
        />
        <MainSectionInfo />
      </div>
      <div className={styles.box}>
        <TenDaysPromocode />
          {/*<Tariffs />*/}
        <MainSectionProduct />
        {/*
        <MainSectionNews />
        */}
        <MainSectionAnalytics />
        <MainSectionEcosystem />
        {/*
        <MainSectionLessons />
        */}
        <MainSectionQuestions />
      </div>
    </main>
  );
}

export default AppMain;
