import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer} from "recharts";
import {useSelector} from "react-redux";
import ArrowLeft from '../../../../../images/arrow-left-card.png';
import Elipse from '../../../../../images/Ellipse.svg';
import BgLeft from '../../../../../images/screener-bg-left.png';
import StarFile from '../../../../../images/StarFile.svg';
import styles from './screener-header.module.css';

const ScreenerPageHeader = () => {
    const {information} = useSelector(state => state.screener);
    const params = useParams()

    const localDataInfoForCreateArray = [
        {subject: 'Оценка стоимости', A: 0},
        {subject: 'Рост', A: 1},
        {subject: 'Прошлые\nпоказатели', A: 2},
        {subject: 'Финансовое\nсостояние', A: 3},
        {subject: 'Дивиденды', A: 4},
    ]

    const value = Object.values(information['snowflake'] || {});

    const newArray = localDataInfoForCreateArray.map((item, index) => ({
        ...item,
        A: value[index],
    }));

    const CustomPolarAngleAxisTick = ({x, y, payload}) => {
        const lines = payload.value.split('\n');
        const customXorY = () => {
            let newX = x;
            let newY = y;
            if (lines.includes('Дивиденды')) {
                newX -= 40;
            }
            if (lines.includes('Рост')) {
                newX += 15;
            }
            if (lines.includes('Финансовое')) {
                newY += 15;
            }
            if (lines.includes('Прошлые')) {
                newY += 15;
            }
            return {newX, newY};
        };

        const {newX, newY} = customXorY();
        return (
            <text
                x={x}
                y={newY}
                textAnchor="middle"
                fontSize={12}
                fill="#f3f2f2">
                {lines.map((line, index) => (
                    <tspan x={newX} dy={index > 0 ? 15 : 0} key={index}>
                        {line}
                    </tspan>
                ))}
            </text>
        );
    };

    const getColorByValue = (value) => {
        if (value <= 8) return '#FF0000';
        if (value >= 9 && value <= 16) return '#FFA500';
        return '#008000';
    };

    const checkReportingCompany = (slug) => {
        if (slug === 'VJGZP' || slug === 'VJGZ' || slug === 'KRKOP') return 'Компания не публикует отчетность с 2021 года.'
        if (slug === 'GAZAP' || slug === 'GAZA') return 'Компания не публикует отчетность с 2017 года.'
        if (slug === 'PAZA') return 'Компания не публикует отчетность с 2018 года.'
        if (slug === 'QIWI' || slug === 'ACKO' || slug === 'VEON-RX') return 'Компания не владеет российскими активами и является иностранным эмитентом.'
        return ''
    }

    return (
        <div className={styles.header}>
            <img className={styles.bgLeft} src={BgLeft} alt="bgLeft"/>
            <div className={styles.container}>
                <div className={styles.navigation}>
                    <Link className={styles.navLink} to={'/'}>
                        <img src={ArrowLeft} alt="arrow"/>
                        Главная</Link>
                    <Link className={styles.navLink} to={'/screener'}>
                        <img src={ArrowLeft} alt="arrow"/>
                        Компании</Link>
                </div>
                <div className={styles.info}>
                    <div className={styles.left}>
                        <div className={styles.up}>
                            <div className={styles.imgCompany}>
                                <img src={information?.['main_info']?.logo} alt="photoCompany"/>
                            </div>
                            <div className={styles.descr}>
                                <h1 className={styles.titleCompany}>{information?.['main_info']?.name}
                                    <img src={StarFile} alt="star"/>
                                </h1>
                                <div className={styles.infoCompany}>
                                    <div
                                        className={styles.infoCompanySubtitle}>{information?.['main_info']?.ticker}</div>
                                    <img src={Elipse} alt="elipse" width={4}/>
                                    <div
                                        className={styles.infoCompanySubtitle}>{information?.['main_info']?.sector}</div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.down}>
                            <div className={styles.downInfo}>
                                <div className={styles.downInfoTitle}>Последняя цена</div>
                                <div className={styles.downPrice}>{information?.['main_info']?.['last_price']} ₽</div>
                            </div>
                            <div className={styles.downInfo}>
                                <div className={styles.downInfoTitle}>Капитализация</div>
                                <div
                                    className={styles.downPrice}>{(information?.['main_info']?.['market_cap'] / 1_000_000_000).toFixed(2)} млрд
                                    ₽
                                </div>
                            </div>
                            <div className={styles.downInfo}>
                                <div className={styles.downInfoTitle}>Динамика за год</div>
                                <div
                                    style={{color: Number(information?.['main_info']?.['year_price_change']) > 0 ? '#37d881' : 'red'}}
                                    className={`${styles.downPrice} ${styles.green}`}>{information?.['main_info']?.['year_price_change']}
                                    %
                                </div>
                            </div>
                        </div>
                        {params?.slug && checkReportingCompany(params.slug) &&
                            <div className={styles.warning}>{checkReportingCompany(params.slug)}</div>}
                    </div>
                    <div className={styles.right}>
                        <ResponsiveContainer width="100%" height="100%">
                            <RadarChart cx={'50%'} cy={'50%'} outerRadius={'80%'} data={newArray}>
                                <PolarGrid stroke={'#f3f2f2'} />
                                <PolarAngleAxis dataKey="subject" tick={<CustomPolarAngleAxisTick/>}/>
                                <PolarRadiusAxis tickCount={6} tick={false} axisLine={false} domain={[0, 5]}/>
                                <Radar dataKey="A" stroke={getColorByValue(information.snowflake?.sum)}
                                        fillOpacity={0.6} fill={getColorByValue(information.snowflake?.sum)}
                                />
                            </RadarChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScreenerPageHeader;
