import {useEffect, useRef, useState} from "react";
import {createChart} from "lightweight-charts";
import {getStocksForChart} from "../../utils/api";
import {useResize} from "../../services/hooks/useResize";
import styles from './price-chart.module.css'

export const PriceChart = ({information, activeButton, period, setIsLoading}) => {
    const chartContainerRef = useRef();
    const screenWidth = useResize()
    const [data, setData] = useState([]);

    useEffect(() => {
        const chartOptions = {
            layout: {
                textColor: 'black',
                background: {type: 'solid', color: 'white'},
            },
            timeScale: {
                timeVisible: true,
                secondsVisible: false
            }
        };
        const chart = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: 500,
        }, chartOptions);

        let series;

        const transformData = data.reduce((acc, item) => {
            if (!acc.some(existingItem => existingItem.time === item.date_unix)) {
                acc.push({
                    open: item.open_price,
                    high: item.highest_price,
                    low: item.lowest_price,
                    close: item.close_price,
                    time: item.date_unix,
                });
            }
            return acc;
        }, []);

        const resultData = transformData.sort((a, b) => a.time - b.time);

        if (activeButton === 'candle') {
            series = chart.addCandlestickSeries({
                upColor: '#26a69a',
                downColor: '#ef5350',
                borderVisible: false,
                wickUpColor: '#26a69a',
                wickDownColor: '#ef5350',
            });
            series.setData(resultData);
        } else if (activeButton === 'line') {
            series = chart.addLineSeries({
                color: '#26a69a',
                lineWidth: 2,
            });
            const lineData = resultData.map(item => ({
                time: item.time,
                value: item.close,
            }));
            series.setData(lineData);
        } else if (activeButton === 'bar') {
            series = chart.addBarSeries({
                upColor: '#26a69a',
                downColor: '#ef5350',
            });
            series.setData(resultData);
        }

        chart.timeScale().fitContent();

        const visibleBars = 100;
        const totalBars = resultData.length;

        if (totalBars > visibleBars) {
            const firstVisibleBarTime = resultData[totalBars - visibleBars].time;
            const lastVisibleBarTime = resultData[totalBars - 1].time;

            chart.timeScale().setVisibleRange({from: firstVisibleBarTime, to: lastVisibleBarTime});
        }

        return () => {
            chart.remove();
        };
    }, [data, activeButton, screenWidth]);

    useEffect(() => {
        setIsLoading(true)
            getStocksForChart(information.main_info.ticker, period).then(async (res) => {
                if (res.ok) {
                    setIsLoading(false)
                    const data = await res.json();
                    setData(data);
                } else {
                    setIsLoading(false)
                    console.log(res);
                }
            });
    }, [information, period]);

    return (
        <div className={styles.priceChartContainer}>
            <div className={styles.priceChartWrapper}>
                <div ref={chartContainerRef} style={{position: 'relative', width: '100%', height: '500px'}}/>
            </div>
        </div>
    );
};
