import {NavLink, useParams} from "react-router-dom";
import {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setIsLoading} from "../../../services/reducers/preloader-slice";
import Preloader from "../../preloader/preloader";
import SEO from "../../../utils/SEO";
import BreadcrumbList from './seo-script';
import {useEducationContext} from "../../../services/context/EducationContext";
import CanMightBeInteresting from "../../can-might-be-interesting/can-might-be-interesting";
import {getLesson, getRecommendedPosts} from "../../../utils/api";
import styles from "./lesson.module.css";
import {setRecommendedPosts} from "../../../services/reducers/recommendedPosts-slice";
import {BreadcrumbNavigation} from "../../breadcrumb-navigation/breadcrumbsNavigation";

function Lesson() {
  const value = useEducationContext()
  const {slug} = useParams();
  const lesson = value.lessonCardData
  const isLoading = useSelector((store) => store.preloader.isLoading);
  const dispatch = useDispatch()
  const themesSlugActive = localStorage.getItem('themeActive')
  const subscriptionUser = JSON.parse(localStorage.getItem("subscription"))
  const token = localStorage.getItem("token");
  const isAuthenticated = !!token;
  const recommendedPosts = useSelector((store) => store.recommendedPosts.recommendedPosts);

  // const subscriptionUnity = localStorage.getItem("subscriptionUnity");
  // const subscriptionIdeas = localStorage.getItem("subscriptionIdeas");

  const checkSubscribe = (cardSubscribe) => {
    if (subscriptionUser) {
      const val = subscriptionUser.some((el) => {
        return el.subscription.includes("Unity")
      })
      return val
    }
  }

  const numberOfHiddenPost = useMemo(() => {
    return !checkSubscribe(lesson.subscription) && lesson.subscription !== null ? 3 : null;
  }, [lesson]);

  const checked = (!isAuthenticated && !checkSubscribe(lesson.subscription)) && lesson.subscription !== null

  useEffect(() => {
    dispatch(setIsLoading(true))
    // dispatch(getLesson(slug))
    value.getLessonCardDataApi(slug)
    getRecommendedPosts(slug).then((res) => {
      dispatch(setRecommendedPosts(res));
    });
  }, [slug]);

  return (
    <>
      <section className={styles.section}>

        <SEO
          title={lesson.name}
          description={lesson.description}
          ogTitle={lesson.name}
          ogDescription={lesson.description}
          script={BreadcrumbList}
          breadTitle={lesson.theme}
          slug={themesSlugActive}
        />
        {isLoading ? <div className={styles.preloader}><Preloader/></div> :
          checked ?
            <div>
              <LessonBreadcrumbs lessonTheme={lesson.theme} themesSlugActive={themesSlugActive}/>
              <div className={styles.main}>
                <div className={styles.mainLeftSide}>
                  <h2 className={styles.name}>{lesson.name}</h2>
                  <div className={styles.aboutLesson}>
                    <span className={styles.priority}>{`Урок ${lesson.priority}`}</span>
                    <div className={styles.dot}></div>
                    <div className={styles.time}>
                      <div className={styles.clock}></div>
                      <span className={styles.timeText}>{lesson.reading_time}</span>
                    </div>
                  </div>
                </div>

                <CanMightBeInteresting
                  posts={recommendedPosts}
                  numberOfHiddenPost={numberOfHiddenPost}
                />
              </div>

              <div className={styles.box}>
                <h3 className={styles.title}>
                  {`Оформите подписку Unity, для получения доступа к материалам`}
                </h3>
                <NavLink to={'/profile'} className={styles.button}>
                  Оформить подписку
                </NavLink>
              </div>
            </div> :
            <div>

              <LessonBreadcrumbs lessonTheme={lesson.theme} themesSlugActive={themesSlugActive}/>

              <div className={styles.main}>
                <div className={styles.mainLeftSide}>

                  <h2 className={styles.name}>{lesson.name}</h2>
                  <div className={styles.aboutLesson}>
                    <span className={styles.priority}>{`Урок ${lesson.priority}`}</span>

                    <div className={styles.dot}></div>
                    <div className={styles.time}>
                      <div className={styles.clock}></div>
                      <span className={styles.timeText}>{lesson.reading_time}</span>
                    </div>
                  </div>
                  <div className={styles.container}
                       dangerouslySetInnerHTML={{__html: lesson.text}}></div>

                  <div className={styles.buttons}>
                    {lesson.previous_lesson === null ? null :
                      <NavLink to={`/lessons/${lesson.previous_slug}`} className={styles.previous}>
                        <div className={styles.left}></div>
                        Предыдущий
                      </NavLink>
                    }

                    {lesson.next_lesson === null ? null :
                      <NavLink to={`/lessons/${lesson.next_slug}`} className={styles.next}>
                        Следующий
                        <div className={styles.right}></div>
                      </NavLink>}

                  </div>
                </div>
                <CanMightBeInteresting
                  posts={recommendedPosts}
                  numberOfHiddenPost={numberOfHiddenPost}
                />
              </div>
            </div>
        }

      </section>
    </>
  )
}

function LessonBreadcrumbs({themesSlugActive, lessonTheme}) {
  return <BreadcrumbNavigation
    className={styles.breadcrumbs}
    links={[
      {label: "Главная", url: "/"}, {label: "Обучение", url: "/education"},
      {label: lessonTheme, url: `/education/${themesSlugActive}`}
    ]}
    forBg={"light"}
    withMarginBottom={false}
  />
}

export default Lesson
