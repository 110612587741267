import React, {useEffect, useState} from 'react';
import {
    fillObjectFromArr,
    generateTableRowTitles,
    generateTableRowTitlesSecond,
    transformObject
} from "./utilsForFinanceTab";
import {getFinanceDataForScreener} from "../../../../../utils/api";
import {FinanceBlock} from "./finance-block/finance-block";
import styles from './screener-tab-finance.module.css'
import Preloader from "../../../../preloader/preloader";

const ScreenerTabFinance = ({information}) => {
    const [data, setData] = useState({})

    const [selectedIncomeRows, setSelectedIncomeRows] = useState(['totalRevenue', 'netIncome']);
    const [selectedBalanceRows, setSelectedBalanceRows] = useState(['totalDebt', 'ev']);
    const [selectedCashFlowRows, setSelectedCashFlowRows] = useState(['totalCashFromOperatingActivities', 'freeCashFlow']);
    const [selectedRatiosRows, setSelectedRatiosRows] = useState(['eps', 'evtoebitda']);

    const [selectedIncomeCompany, setSelectedIncomeCompany] = useState({})
    const [selectedBalanceCompany, setSelectedBalanceCompany] = useState({})
    const [selectedCashFlowCompany, setSelectedCashFlowCompany] = useState({})
    const [selectedRatiosCompany, setSelectedRatiosCompany] = useState({})

    const [periodIncome, setPeriodIncome] = useState('Y')
    const [periodBalance, setPeriodBalance] = useState('Y')
    const [periodCashFlow, setPeriodCashFlow] = useState('Y')
    const [periodRatios, setPeriodRatios] = useState('Y')

    const [valuesIncome, setValuesIncome] = useState('rub')
    const [valuesBalance, setValuesBalance] = useState('rub')
    const [valuesCashFlow, setValuesCashFlow] = useState('rub')
    const [valuesRatios, setValuesRatios] = useState('rub')

    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const selectedCompany = (key, isForInputSearch = false) => {
        if (key === 'incomeStatement' && selectedIncomeCompany?.company) return isForInputSearch ? selectedIncomeCompany.company : selectedIncomeCompany.data.incomeStatement
        if (key === 'balanceSheet' && selectedBalanceCompany?.company) return isForInputSearch ? selectedBalanceCompany.company : selectedBalanceCompany.data.balanceSheet
        if (key === 'cashFlow' && selectedCashFlowCompany?.company) return isForInputSearch ? selectedCashFlowCompany.company : selectedCashFlowCompany.data.cashFlow
        if (key === 'ratios' && selectedRatiosCompany?.company) return isForInputSearch ? selectedRatiosCompany.company : selectedRatiosCompany.data.ratios
        return ''
    }

    const selectedRowsForMapping = (key, isSet = false) => {
        if (key === 'incomeStatement') return isSet ? setSelectedIncomeRows : selectedIncomeRows
        if (key === 'balanceSheet') return isSet ? setSelectedBalanceRows : selectedBalanceRows
        if (key === 'cashFlow') return isSet ? setSelectedCashFlowRows : selectedCashFlowRows
        if (key === 'ratios') return isSet ? setSelectedRatiosRows : selectedRatiosRows
    }

    const setSelectedCompany = (key) => {
        if (key === 'incomeStatement') return setSelectedIncomeCompany
        if (key === 'balanceSheet') return setSelectedBalanceCompany
        if (key === 'cashFlow') return setSelectedCashFlowCompany
        if (key === 'ratios') return setSelectedRatiosCompany
    }

    const dataPeriod = (key, isSet = false) => {
        if (key === 'incomeStatement') return isSet ? setPeriodIncome : periodIncome
        if (key === 'balanceSheet') return isSet ? setPeriodBalance : periodBalance
        if (key === 'cashFlow') return isSet ? setPeriodCashFlow : periodCashFlow
        if (key === 'ratios') return isSet ? setPeriodRatios : periodRatios
    }

    const dataValues = (key, isSet = false) => {
        if (key === 'incomeStatement') return isSet ? setValuesIncome : valuesIncome
        if (key === 'balanceSheet') return isSet ? setValuesBalance : valuesBalance
        if (key === 'cashFlow') return isSet ? setValuesCashFlow : valuesCashFlow
        if (key === 'ratios') return isSet ? setValuesRatios : valuesRatios
    }

    useEffect(() => {
        setIsLoading(true)
        getFinanceDataForScreener(information.main_info.ticker, 'Y').then(async (res) => {
            const reorderedObj = {};
            let ttmValues = {}
            let averageFiveYearsValues = {}
            let averageTenYearsValues = {}

            if (res.ttm) ttmValues = transformObject(res.ttm, 'ttm')
            if (res.averages_5y) averageFiveYearsValues = transformObject(res.averages_5y, 'average5')
            if (res.averages_10y) averageTenYearsValues = transformObject(res.averages_10y, 'average10')

            if (res.data.incomeStatement) reorderedObj.incomeStatement = fillObjectFromArr(res.data.incomeStatement, ttmValues.incomeStatement, averageFiveYearsValues.incomeStatement, averageTenYearsValues.incomeStatement)
            if (res.data.balanceSheet) reorderedObj.balanceSheet = fillObjectFromArr(res.data.balanceSheet, ttmValues.balanceSheet, averageFiveYearsValues.balanceSheet, averageTenYearsValues.balanceSheet)
            if (res.data.cashFlow) reorderedObj.cashFlow = fillObjectFromArr(res.data.cashFlow, ttmValues.cashFlow, averageFiveYearsValues.cashFlow, averageTenYearsValues.cashFlow)
            if (res.data.ratios) reorderedObj.ratios = fillObjectFromArr(res.data.ratios, ttmValues.ratios, averageFiveYearsValues.ratios, averageTenYearsValues.ratios)

            setData(reorderedObj)
            setIsLoading(false)
        })
            .catch((e) => {
                console.log(e)
                setError('Финансовые данные по данной компании отсутствуют')
                setIsLoading(false)
            });

    }, [information]);

    return (
        <div className={styles.financeTab}>
            {isLoading ? <div className={styles.preloaderWrapper}><Preloader/></div> :
                <>
                    {error ? <div className={styles.error}>{error}</div> :
                        Object.entries(data).map(([key, value], index) => {
                            const period = dataPeriod(key)
                            const values = dataValues(key)
                            const tableRowTitles = generateTableRowTitles(value, period);
                            const tableRowTitlesSecond = generateTableRowTitlesSecond(selectedCompany(key), period);

                            return <FinanceBlock key={`${key + index}-${Math.random()}`} keyBlock={key}
                                                 tableRowTitles={tableRowTitles}
                                                 tableRowTitlesSecond={tableRowTitlesSecond}
                                                 selectedRowsForMapping={selectedRowsForMapping}
                                                 setSelectedCompany={setSelectedCompany}
                                                 selectedCompany={selectedCompany} value={value}
                                                 information={information}
                                                 period={period} setPeriod={dataPeriod(key, true)} setData={setData}
                                                 values={values} setValues={dataValues(key, true)}
                            />
                        })}
                </>}

        </div>
    );
};

export default ScreenerTabFinance;
