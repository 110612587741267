import { useContext, useState  } from "react";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getWebinarsData } from "../actions/user";
import {
  isClickEnter,
  isUserDelete
} from "../reducers/modal-slice"
import { setAuthChecked, setUser,  } from "../../services/reducers/user-slice"
import { useDispatch } from "react-redux";

const WebinarContext = React.createContext()

export const useWebinar = () => {
return useContext(WebinarContext)
}

export const WebinarProvider = ({ children }) => {
const [webinarsData, setWebinarsData] = useState(false)
const [tabNumber, setTabNumber] = useState(0)
const [tabName, setTabName] = useState(false)
const dispatch = useDispatch()
const navigate = useNavigate()
const logout = () => {
  navigate("/authorization")
  localStorage.removeItem("token")
  localStorage.removeItem("subscription")
  dispatch(setUser(null))
  dispatch(setAuthChecked(false))
  dispatch(isUserDelete(true))
  dispatch(isClickEnter(false))
}

  const getWebinarDataApi = () => {
    getWebinarsData()
    .then(async (res) => {
      if (res.ok) {
        const data = await res.json()
        setWebinarsData(data)
      const obj = Object.keys(data.webinars)
      setTabName(obj[0])
      } else {
        return res.json()
      }
    })
    .then((res) => {
      if (!!res) {
        console.log(res.detail);
      }
      if (!!res && res.detail === "Недопустимый токен.") {
        logout()// Включить для выброса!!!!!!!!!!!!!!!!!
      }
    })
    // .then((res) => {
    //   console.log(res);
    //   return res
    // })
    // .then((res) => {
    //   setWebinarsData(res)
    //   const obj = Object.keys(res.webinars)
    //   setTabName(obj[0])
    // })
  }

  return (
    <WebinarContext.Provider value={{
      getWebinarDataApi,
      webinarsData,
      setTabNumber,
      tabNumber,
      setTabName,
      tabName,

    }}>
      {/* children */}
      <Outlet/>
    </WebinarContext.Provider>
  )
}