import {useCallback} from 'react';
import {NavLink} from 'react-router-dom';
import styles from './can-might-be-interesting.module.css';

function CanMightBeInteresting({posts, numberOfHiddenPost, blogData}) {
    const subscriptionUser = JSON.parse(localStorage.getItem('subscription'));
    const randomIndex = Math.floor(Math.random() * ((blogData?.length - 1) - 1) + 1);
    console.log(randomIndex)
    // const breakMap = () => {
    //   let newPosts = [...posts]
    //   const heightCard = 140
    //   const distanceToFooter = window.innerHeight - newPosts.length * heightCard
    //   if (distanceToFooter > 120 && distanceToFooter < 240) {
    //     newPosts.length = 5
    //   } else if (distanceToFooter < 120 && distanceToFooter > 0) {
    //     newPosts.length = 4
    //   } else if (distanceToFooter < 120 ) {
    //     newPosts.length = 3
    //   }
    //   return newPosts
    // }
    const anRecom = useCallback(() => {

        if (!!window.ym) {
            window.ym(96742836, 'reachGoal', 'an-recom');
        }
    }, []);

    const checkSubscribe = (cardSubscribe) => {
        if (subscriptionUser) {
            return subscriptionUser.some((el) => {
                return el.subscription === cardSubscribe;
            });
        }
        return false;
    };

    const posts1 = [
        {
            subscription: "Unity Россия",
            name: 'Сколько дивидендов Займер заплатит в 2024 году? Интервью с менеджментом',
            slug: 'slug',
            url: 'url',
            pub_date: "2024-07-25T22:32:26+03:00",
            pub_date_formated: "25 июля 2024 в 22:32"
        }
    ]

    const transformDate = (date) => {
        const months = {
            0: 'января',
            1: 'февраля',
            2: 'марта',
            3: 'апреля',
            4: 'мая',
            5: 'июня',
            6: 'июля',
            7: 'августа',
            8: 'сентября',
            9: 'октября',
            10: 'ноября',
            11: 'декабря'
        };

        const newDate = new Date(date);

        const day = newDate.getUTCDate();
        const month = months[newDate.getUTCMonth()];
        const year = newDate.getUTCFullYear();
        const time = newDate.toLocaleTimeString('ru-RU', {
            hour: '2-digit',
            minute: '2-digit',
        });

        return `${day} ${month} ${year} в ${time}`;
    }

    return (
        <div className={styles.canMightBeInteresting}>
            <h3 className={styles.cmbiTitle}>Может быть интересно</h3>

            {posts.filter((_, i) => i < (numberOfHiddenPost ? numberOfHiddenPost : 100)).map((element) => (
                <NavLink
                    onClick={anRecom}
                    to={element.hasOwnProperty('special_mark_data') ? `/polezno-investoru/${element.special_mark_data?.slug}/${element.slug}` : `/analytics-and-news/${element.slug}`}
                    className={styles.linkBox}
                    key={element.name}>
                    <div className={styles.canMightBeInterestingCard}>
                        <div style={element.subscription ? {} : {display: 'none'}} className={styles.cardBox}>
                            {element.subscription === null ? null : (
                                <span className={styles.subscription}>{`С подпиской ${element.subscription}`}</span>
                            )}
                        </div>
                        <span className={styles.titleBox}>{element.name}</span>
                        <span className={styles.pubDateBox}>
                            {element.url ? element.pub_date_formated : element.pub_date_formated}
                        </span>
                    </div>
                </NavLink>
            ))}
            {posts?.length !== 0 && blogData && blogData[randomIndex] && (
                <NavLink
                    onClick={anRecom}
                    to={`/polezno-investoru/${blogData[randomIndex]['special_mark_data']['slug']}/${blogData[randomIndex]['slug']}`}
                    className={styles.linkBox}
                    key={blogData[randomIndex].name}>
                    <div className={styles.canMightBeInterestingCard}>
                        <span className={styles.titleBox}>{blogData[randomIndex].name}</span>
                        <span className={styles.pubDateBox}>
                            {transformDate(blogData[randomIndex]['pub_date'])}
                        </span>
                    </div>
                </NavLink>
            )}
        </div>
    );
}

export default CanMightBeInteresting;
