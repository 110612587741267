import React from 'react';

const ScreenerTabDocs = () => {
    return (
        <div>
            <h2>Документы</h2>
        </div>
    );
};

export default ScreenerTabDocs;