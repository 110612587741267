import SearchSection from "../search/search"
import Gallery from "../gallery/gallery"
import MainSectionSocialMediaNew from "../main-section-social-media-new/main-section-social-media-new"
import SEO from "../../utils/SEO"
import SeoScriptMain from './seo-script';
import {BreadcrumbNavigation} from "../breadcrumb-navigation/breadcrumbsNavigation";
import styles from "./main.module.css"

const AppMainAnn = () => {
    return (
        <>
            <SEO
                title="Аналитика и новости от Invest Era"
                description="Актуальные новости с комментариями экспертов, аналитика по компаниям из России, США и Китая."
                ogTitle="Аналитика и новости от Invest Era"
                ogDescription="Актуальные новости с комментариями экспертов, аналитика по компаниям из России, США и Китая."
                script={SeoScriptMain}
            />
            <main className={styles.app}>
                <BreadcrumbNavigation className={styles.breadcrumbs} links={[{url: "/", label: "Главная"}]} forBg={"light"} />
                <SearchSection/>
                <Gallery/>
            </main>
            <MainSectionSocialMediaNew/>
        </>
    )
}

export default AppMainAnn
