import React, {useEffect, useRef, useState} from 'react';
import Player from '@vimeo/player';
import styles from './vimeo-video.module.css'
import {useResize} from "../../../../../services/hooks/useResize";

const VimeoPlayer = ({videoId, title, description}) => {
    const iframeRef = useRef(null);
    const screenWidth = useResize()

    /*    const [isExpandedTimeCodes, setIsExpandedTimeCodes] = useState(false);

        const seekTo = (seconds) => {
            if (iframeRef.current && iframeRef.current.player) {
                iframeRef.current.player.setCurrentTime(seconds).then(() => {
                    iframeRef.current.player.play();
                }).catch((error) => {
                    console.error(error);
                });
            }
        };

        const toggleTimeCodesList = () => {
            setIsExpandedTimeCodes(!isExpandedTimeCodes);
        };*/

    useEffect(() => {
        if (iframeRef.current) {
            iframeRef.current.player = new Player(iframeRef.current);
        }
    }, []);

    return (
        <div>
            <div className={styles.videoWrapper}>
            <iframe
                ref={iframeRef}
                src={`https://player.vimeo.com/video/${videoId}`}
                // height='195'
                // width={'100%'}
                frameBorder="0"
                allow="autoplay; fullscreen"
                allowFullScreen
            ></iframe>
            </div>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>
                {description}
            </div>
            {/*            <ul className={styles.timeCodes}>
                <li>
                    <button onClick={() => seekTo(10)} className={styles.timeCode}>
                        <span className={styles.time}>0:10</span> - Тайм-код 1
                    </button>
                </li>
                <li>
                    <button onClick={() => seekTo(60)} className={styles.timeCode}>
                        <span className={styles.time}>1:00</span> - Тайм-код 2
                    </button>
                </li>
                {isExpandedTimeCodes && (
                    <>
                        <li>
                            <button onClick={() => seekTo(120)} className={styles.timeCode}>
                                <span className={styles.time}>2:00</span> - Тайм-код 3
                            </button>
                        </li>
                        <li>
                            <button onClick={() => seekTo(180)} className={styles.timeCode}>
                                <span className={styles.time}>3:00</span> - Тайм-код 4
                            </button>
                        </li>
                        <li>
                            <button onClick={() => seekTo(240)} className={styles.timeCode}>
                                <span className={styles.time}>4:00</span> - Тайм-код 5
                            </button>
                        </li>
                    </>
                )}
            </ul>
            <button onClick={toggleTimeCodesList} className={styles.toggleButton}>
                {isExpandedTimeCodes ? 'Скрыть' : 'Показать все'}
            </button>*/}
        </div>
    );
};

export default VimeoPlayer;
