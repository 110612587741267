import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {useMediaQuery} from 'react-responsive';
import {getCard3, getRecommendedPosts} from '../../../utils/api';
import {setIsLoading} from '../../../services/reducers/preloader-slice';
import Preloader from '../../preloader/preloader';
import CanMightBeInteresting from '../../can-might-be-interesting/can-might-be-interesting';
import {setAuthChecked, setUser} from '../../../services/reducers/user-slice';
import {isClickEnter, isUserDelete} from '../../../services/reducers/modal-slice';
import BreadcrumbList from './seo-script';
import {setCard} from '../../../services/reducers/single-card-slice';
import {setRecommendedPosts} from '../../../services/reducers/recommendedPosts-slice';
import SEO from '../../../utils/SEO';
import {useActiveButtonCountryTagChoice} from '../../../services/context/CountryFilterContext';
import AboutCardMailSection from '../about-card-mail/about-card-mail';
import {OurProductsForAboutCard} from "./our-products-for-about-card/our-products-for-about-card";
import {useTheme} from "../../../services/hooks/useTheme";
import {getUserProfileData} from "../../../services/actions/user";
import styles from './about-card.module.css';

function AboutCard() {
    const card = useSelector((store) => store.singleCard.card);
    const isLoading = useSelector((store) => store.preloader.isLoading);
    const recommendedPosts = useSelector((store) => store.recommendedPosts.recommendedPosts);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const val = useActiveButtonCountryTagChoice();
    const {slug} = useParams();
    const isMobile = useMediaQuery({query: '(max-width: 760px)'});
    const {theme} = useTheme();
    const containerRef = useRef(null);
    const componentRef = useRef(null);
    const [localSubsData, setLocalSubsData] = useState([]);
    const [subsFlag, setSubsFlag] = useState(false);
    const [marginValue, setMarginValue] = useState(false);
    const [localLoading, setLocalLoading] = useState(false);
    const [errorToken, setErrorToken] = useState(true);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const logout = () => {
        navigate('/authorization');
        localStorage.removeItem('token');
        localStorage.removeItem('subscription');
        dispatch(setUser(null));
        dispatch(setAuthChecked(false));
        dispatch(isUserDelete(true));
        dispatch(isClickEnter(false));
    };

    useEffect(() => {
        dispatch(setIsLoading(true));

        getCard3(slug, errorToken)
            .then(async (res) => {
                const path = {
                    404: '/not-found',
                    // 401: "/authorization",
                    403: '/error-403',
                    500: '/error-500',
                };

                if (res.ok) {
                    const data = await res.json();
                    dispatch(setCard(data));
                } else if (res.status in path) {
                    setErrorToken(false);
                    val.setBackendErrorCheck(true);
                    navigate(path[res.status]);
                } else {
                    setErrorToken(false);
                    return res.json();
                }
            })
            .then((res) => {
                if (!!res) {
                    console.log(res.detail);
                }
                if (!!res && res.detail === 'Недопустимый токен.') {
                    logout(); // Включить для выброса!!!!!!!!!!!!!!!!!
                }
            })
            .finally(() => {
                dispatch(setIsLoading(false));
            });

        getRecommendedPosts(slug).then((res) => {
            dispatch(setRecommendedPosts(res));
        }).catch((e)=>{
           if(e === 'Ошибка 500') {
               dispatch(setRecommendedPosts([]));
           } else {
               console.error(e)
           }
        });
    }, [slug, navigate, errorToken]);

    const subscriptionUser = JSON.parse(localStorage.getItem('subscription'));

    const checkSubscribe = (cardSubscribe) => {
        if (subscriptionUser) {
            return subscriptionUser.some((el) => {
                return el.subscription === cardSubscribe;
            });
        }
        return false;
    };

    // замена ссылки
    function addLink() {
        let element = document.getElementById('sectionCard');
        let selection = window.getSelection();
        //Итоговый скопированный текст
        let copytext = `${card.name}` + '\n' + 'Читайте на сайте: ' + document.location.href;
        let newdiv = document.createElement('div');
        newdiv.style.position = 'absolute';
        newdiv.style.left = '-99999px';
        element.appendChild(newdiv);
        newdiv.innerText = copytext;
        selection.selectAllChildren(newdiv);
        window.setTimeout(function () {
            element.removeChild(newdiv);
        }, 0);
    }

    document.oncopy = addLink;

    // const [fixedBottom, setFixedBottom] = useState(false);
    // const fixedSectionComponentRef = useRef(null);
    // const topRectFreezeRef = useRef(null);
    // const triggerTop = useRef();

    // const scrollHandler = useCallback(() => {
    //   let viewportHeight = document.body.scrollHeight - 650; // Высота видимой части окна 224 footer + 300 mail section
    //   let scrollY = window.pageYOffset; // Величина прокрутки по вертикали
    //   let innertHeight = window.innerHeight;
    //   let topRect;
    //   // let distanceToPageEnd
    //   if (fixedSectionComponentRef.current) {
    //     const blockRect = fixedSectionComponentRef.current.getBoundingClientRect();
    //     topRect = blockRect;

    //     if (!topRectFreezeRef.current) {
    //       topRectFreezeRef.current = topRect.bottom;
    //     }
    //     if (!triggerTop.current) {
    //       triggerTop.current = viewportHeight - topRectFreezeRef.current;
    //     }

    //     // console.log(triggerTop.current );
    //     // console.log(triggerTop.current );

    //     if (scrollY + topRectFreezeRef.current > viewportHeight) {
    //       setFixedBottom(true);
    //     } else {
    //       setFixedBottom(false);
    //     }
    //   }

    //   let top;
    //   if (componentRef.current) {
    //     const topCurrent = componentRef.current.getBoundingClientRect();
    //     top = topCurrent.top;
    //   }

    //   const isVisible = top >= 0 && top <= window.innerHeight;

    //   if (isVisible) {
    //     setMarginValue(false);
    //   } else {
    //     if (componentRef.current) {
    //       setMarginValue(true);
    //     }
    //   }
    // }, [fixedSectionComponentRef.current]);

    // if (fixedSectionComponentRef.current) {
    //   disableScroll(false)
    // }

    /*  useEffect(() => {
        if (!recommendedPosts.length) {
          document.documentElement.style.overflow = 'hidden';
          document.body.style.overflow = 'hidden';
        } else {
          document.documentElement.style.overflow = 'auto';
          document.body.style.overflow = 'auto';
        }
        return () => {
          document.documentElement.style.overflow = 'auto';
          document.body.style.overflow = 'auto';
        };
      }, [recommendedPosts.length]);*/

    // useEffect(() => {
    //   window.addEventListener('scroll', scrollHandler, false);
    //   return () => window.removeEventListener('scroll', scrollHandler, false);
    // }, [scrollHandler]);

    const numberOfHiddenPost = useMemo(() => {
        return subsFlag === false ? 9 : 9;
    }, [card]);

    const moreAn = useCallback(() => {
        if (!!window.ym) {
            window.ym(96742836, 'reachGoal', 'more_an');
        }
    }, []);

    //эффекст смены цвета html который приходит с сервера
    useEffect(() => {
        if (containerRef.current) {
            const color = theme === 'app_light_theme' ? '#172339' : '#f5f5f5';

            // Изменение цвета текста для всех элементов внутри контейнера
            const allTextElements = containerRef.current.querySelectorAll('*');
            allTextElements.forEach(element => {
                element.style.color = color;
            });
        }
    }, [theme, containerRef.current]);

        if (containerRef.current) {
            const color = theme === 'app_light_theme' ? '#172339' : '#f5f5f5';

            // Изменение цвета текста для всех элементов внутри контейнера
            const allTextElements = containerRef.current.querySelectorAll('*');
            allTextElements.forEach(element => {
                element.style.color = color;
            });
        }

    //обновленный вариан получения списка подписок и отображения валиации в карточках(старый вариан через localStorage не обновляется если изменилась подписка)
    useEffect(() => {
        function getUserSubscription() {
            const token = localStorage.getItem('token');
            const headers = {
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache',
            };
            if (!!token) {
                headers.Authorization = `Token ${token}`;
            }
            return (
                axios.get(`api/users/profile/subscriptions`, {
                    method: 'GET',
                    headers,
                })
                    .then(res => setLocalSubsData(res?.data))
                    .catch((err) => {
                        console.error(err);
                    })
            );
        }

        getUserSubscription();
    }, []);

    useEffect(() => {
        setLocalLoading(true);

        function getSubs() {
            let subsFlag = false;

            localSubsData.forEach((item) => {
                if (item?.name === card?.subscription && item?.['is_active'] === true) {
                    subsFlag = true;
                } else if (item?.name === 'Ideas All' && item?.['is_active'] === true && card?.subscription?.includes("Ideas")) {
                    subsFlag = true;
                } else if (item?.name === 'Unity All' && item?.['is_active'] === true && card?.subscription?.includes("Unity")) {
                    subsFlag = true;
                } else if (item?.name === 'Premium' && item?.['is_active'] === true && card?.subscription?.includes("Unity")) {
                    subsFlag = true;
                } else if (card?.subscription === null) {
                    subsFlag = true;
                } else if (item?.name === 'Unity+Ideas' && item?.['is_active'] === true && (card?.subscription?.includes("Ideas") || card?.subscription?.includes("Unity"))) {
                    subsFlag = true;
                }
            });

            setSubsFlag(subsFlag);
        }

        if (localSubsData.length !== 0 && card) {
            getSubs();
            setLocalLoading(false);
        }
    }, [card, localSubsData]);

    useEffect(() => {
        setLoading(true);
        const getData = async () => {
            try {
                const response = await axios.get(`/api/blog/`);
                setData(response.data);
                setLoading(false);
            } catch (e) {
                console.log(e?.response?.status);
                setLoading(false);
            }
        }
        if (slug) {
            getData();
        }
    }, []);

    useEffect(() => {
        const formattingSubscription = (sub) => {

            const subVal = sub.map((el) => {
                if (el.subscription === "Ideas All") {
                    return [el, {subscription: "Ideas World"}, {subscription: "Ideas Россия"}]
                } else if (el.subscription === "Unity All") {
                    return [el, {subscription: "Unity World"}, {subscription: "Unity Россия"}]
                } else if (el.subscription === "Premium") {
                    return [el, {subscription: "Unity World"}, {subscription: "Unity Россия"}, {subscription: "Unity All"}]
                } else {
                    return el
                }
            })

            const flatSubVal = subVal.flat();

            const uniqueSubscriptions = flatSubVal.filter((item, index, self) => {
                return index === self.findIndex((el) => el.subscription === item.subscription);
            });

            return uniqueSubscriptions
        }

        getUserProfileData().then(async(res) => {
            const data = await res.json()
            if(!data.hasOwnProperty('error')) {
            const subs = JSON.stringify(formattingSubscription(data.subscription))
            localStorage.setItem("subscription", subs)
            }
        })
    }, []);

    return (
        <>
            <section className={styles.card}>
                {/* =============== isLoading ? ================ */}
                {isLoading || loading ? (
                    <div className={styles.preloader}>
                        <Preloader/>
                    </div>
                ) : (
                    <div className={styles.mainScreen}>
                        <SEO
                            title={card.title_seo ? card.title_seo : card.name}
                            description={card.description}
                            ogTitle={card.title_seo ? card.title_seo : card.name}
                            ogDescription={card.description}
                            script={BreadcrumbList}
                        />
                        <div className={styles.sectionCard} id={'sectionCard'}>
                            <div className={styles.aboutCard}>
                                <div className={styles.navigationBox}>
                                    <NavLink to={'/'} className={styles.link}>
                                        <div className={styles.arrow}></div>
                                        Главная
                                    </NavLink>

                                    <div
                                        onClick={() => {
                                            navigate('/analytics-and-news');
                                        }}
                                        className={styles.link}>
                                        <div className={styles.arrow}></div>
                                        Аналитика и Новости
                                    </div>
                                </div>

                                <div className={`${
                                    checkSubscribe(card.subscription) || card.subscription === null
                                        ? styles.headerTitleWrapper
                                        : styles.headerTitleWrapperBlur
                                }`}>
                                    <span className={styles.pubDate}>{card.pub_date_formated}</span>
                                    <h2 className={styles.title}>{card.name}</h2>
                                    <span className={styles.desc}>{card.description}</span>

                                    <div>
                                    <div
                                        ref={containerRef}
                                        style={{color: theme === 'app_light_theme'? '#172339' : '#f5f5f5'}}
                                        className={`${styles.container} ${theme === 'app_light_theme' ? styles.containerLightColor : styles.containerDarkColor}`}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{ __html: card.text }}
                                        />
                                        {!isMobile &&
                                            !isLoading &&
                                            card.country_tag &&
                                            card.country_tag.length !== 0 &&
                                            (checkSubscribe(card.subscription) || card.subscription === null) && (
                                                <div className={styles.sectionBtnMore}>
                                                    <NavLink
                                                        to={`/analytics-and-news/?country=${card.country_tag[0].id}`}
                                                        onClick={moreAn}
                                                        className={styles.linkButton}>{`Больше аналитики и новостей`}</NavLink>
                                                </div>
                                            )}
                                        {isMobile &&
                                            !isLoading &&
                                            card.country_tag &&
                                            card.country_tag.length !== 0 &&
                                            (checkSubscribe(card.subscription) || card.subscription === null) && (
                                                <div className={styles.sectionBtnMore}>
                                                    <NavLink
                                                        to={`/analytics-and-news/?country=${card.country_tag[0].id}`}
                                                        onClick={moreAn}
                                                        className={styles.linkButton}
                                                    >{`Больше аналитики и новостей`}</NavLink>
                                                </div>
                                            )}
                                    </div>
                                    </div>
                                </div>

                                {card.subscription !== null && !checkSubscribe(card.subscription) && (
                                    <div className={styles.authBoxContainer}>
                                        <div className={styles.authBox}>
                                            <div className={styles.authTitle}>{`Полная версия доступна с подпиской ${
                                                card.subscription ? card.subscription : ''
                                            }`}</div>
                                            <div className={styles.subTitle}>
                                                {' '}
                                                Оформите подписку {card?.subscription} и получите доступ к свежей аналитике и
                                                новостям
                                            </div>
                                            <NavLink
                                                to={'/profile'}
                                                className={styles.button}>
                                                <div className={styles.buttonTxt}>Оформить подписку</div>
                                            </NavLink>
                                            <div className={styles.subTxtWrapper}>
                                                <div className={styles.tableModalLoginBtn}>
                                                    Если у Вас уже есть подписка, то
                                                    <NavLink to={'/profile'} className={styles.tableModalLoginBtnLink}>
                                                        войдите
                                                    </NavLink>
                                                </div>
                                                <div className={styles.authText}>
                                                    Остались вопросы? Вы всегда можете{' '}
                                                    <a
                                                        href={'https://t.me/ie_contact'}
                                                        target={'_blank'}
                                                        className={styles.authLink} rel="noreferrer">
                                                        связаться с нами
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className={styles.ourProductsBlock}>
                                    <OurProductsForAboutCard/>
                                </div>
                            </div>

                            {isLoading ? (
                                <Preloader/>
                            ) : (
                                <div className={styles.monitorWrapper} ref={componentRef}>
                                    <div
                                        className={
                                            marginValue &&
                                            (checkSubscribe(card.subscription) || card.subscription === null)
                                                ? styles.monitorHidden
                                                : styles.monitor
                                        }>
                                        {recommendedPosts.length > 0 &&
                                                <CanMightBeInteresting
                                                posts={recommendedPosts}
                                                numberOfHiddenPost={numberOfHiddenPost}
                                                blogData={data?.results}
                                            />
                                        }
                                    </div>
                                </div>
                            )}
                        </div>

                        {recommendedPosts.length > 0 && <div className={styles.phone}>
                            <CanMightBeInteresting hidden={'none'} posts={recommendedPosts}/>
                        </div>}
                    </div>
                )}

                {!isLoading && !loading && <AboutCardMailSection/>}
            </section>
        </>
    );
}

export default AboutCard;