import React from "react";
import PropTypes from "prop-types";
import clsx from "classnames";
import arrowLeftForWhiteBg from "../../images/arrow-left-card.png";
import arrowLeftForDarkBg from "../../images/arrow-left-navigate-button.svg";
import styles from "./breadcrumbsNavigation.module.css";
import {NavLink} from "react-router-dom";


export const BreadcrumbNavigation = ({links, forBg = "dark", className, withMarginBottom = true}) => {
  const arrowLeft = forBg === "light" ? arrowLeftForWhiteBg : arrowLeftForDarkBg;
  const linkClass = clsx(styles.link, {[styles.linkForDarkBg]: forBg === "dark"});
  const wrapperClass = clsx(styles.navigationBox, className, {[styles.marginBottom]: withMarginBottom});

  return (
    <div className={wrapperClass}>
      {links.map((link, index) => (
        <NavLink key={index} className={linkClass} to={link.url}>
          <img alt="arrow to left" src={arrowLeft}/>
          {link.label}
        </NavLink>
      ))}
    </div>
  );
};

// BreadcrumbNavigation.propTypes = {
//   links: PropTypes.arrayOf(
//     PropTypes.shape({
//       label: PropTypes.string.isRequired,
//       url: PropTypes.string.isRequired,
//     })
//   ).isRequired,
//   forBg: PropTypes.oneOf(["light", "dark"]).isRequired,
//   className: PropTypes.string,
//   withMarginBottom: PropTypes.bool,
// };
