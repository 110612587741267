import {useCallback, useEffect, useRef,} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useMediaQuery} from "react-responsive"
import Card from "../card/card"
import {useActiveFilters} from "../../services/context/ActiveFiltersContext"
import {setIsLoading} from "../../services/reducers/preloader-slice"
import Preloader from "../preloader/preloader"
import styles from "./gallery.module.css"

function Gallery() {
    const dispatch = useDispatch()
    const contextValue = useActiveFilters()
    const mountedComponent = useRef(false)
    const galleryRef = useRef(null)
    const isMobile = useMediaQuery({query: '(max-width: 800px)'});
    const countryPoints = useSelector((store) => store.cardsFiltred.countryPoints)
    const companyPoints = useSelector((store) => store.cardsFiltred.companyPoints)
    const sectorPoints = useSelector((store) => store.cardsFiltred.sectorPoints)
    const categoryPoints = useSelector((store) => store.cardsFiltred.categoryPoints)
    const analysisPoints = useSelector((store) => store.cardsFiltred.analysisPoints)
    const subscriptionPoints = useSelector((store) => store.cardsFiltred.subscriptionPoints)
    const bondsPoints = useSelector((store) => store.cardsFiltred.bondsPoints)
    const changePortfolios = useSelector((store) => store.cardsFiltred.changePortfolios)
    const isLoading = useSelector((store) => store.preloader.isLoading)
    const orderingPoints = useSelector((store) => store.cardsFiltred.orderingPoints)

    const getCardWithFilters = useCallback(() => {
        contextValue.setAllGalleryCards(
            orderingPoints,
            categoryPoints,
            countryPoints,
            sectorPoints,
            companyPoints,
            analysisPoints,
            subscriptionPoints,
            bondsPoints,
            changePortfolios
        )
    }, [
        orderingPoints,
        categoryPoints,
        countryPoints,
        sectorPoints,
        companyPoints,
        analysisPoints,
        subscriptionPoints,
        bondsPoints,
        changePortfolios,
        contextValue.pageCount
    ])

    const handleScroll = () => {
        contextValue.scrollPosition.current = window.scrollY
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - (isMobile ? 1200 : 800) && !isLoading) {
            loadMoreCards()
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [isLoading, contextValue.filterCards])

    const loadMoreCards = useCallback(() => {
        if (contextValue.filterCards.length % 18 === 0 || contextValue.filterCards.length % 12 === 0) {
            contextValue.mounted.current = false
            dispatch(setIsLoading(true))
            contextValue.setPageCount((prev) => prev + 1)
        }
    }, [contextValue.pageCount, isLoading, contextValue.filterCards])

    useEffect(() => {
        if (!contextValue.mounted.current && mountedComponent.current) {
            getCardWithFilters()
        }
    }, [contextValue.pageCount])

    useEffect(() => {
        if (!mountedComponent.current) {
            mountedComponent.current = true
        } else {
            contextValue.setFilterCards([])
            contextValue.setPageCount(1)
            dispatch(setIsLoading(true))
            getCardWithFilters()
        }

    }, [
        orderingPoints,
        categoryPoints,
        countryPoints,
        sectorPoints,
        companyPoints,
        analysisPoints,
        subscriptionPoints,
        bondsPoints,
        changePortfolios,
    ])

    useEffect(() => {
        if (contextValue.mounted.current) {
            window.scrollTo(0, parseInt(contextValue.scrollPosition.current, 10));
        }
        if (!contextValue.mounted.current) {
            if (contextValue.filterCards.length === 0) {
                dispatch(setIsLoading(true))
                if (!contextValue.hasUrl.current) {
                    getCardWithFilters()
                    contextValue.hasUrl.current = false
                }
            }
        }
        return () => {
            contextValue.mounted.current = true
        }
    }, [])

    return (
        <section className={styles.sectionDisable}>
            {isLoading && !contextValue.filterCards.length ? (
                <div className={styles.preloader}>
                    <Preloader/>
                </div>
            ) : (
                <div>
                    <div className={styles.gallery} ref={galleryRef}>
                        {contextValue.filterCards.map((element, _, oneEl) => (
                            <Card item={element} oneEl={oneEl} key={element.id + Math.random()}/>
                        ))}
                    </div>
                    {isLoading && (
                        <div className={styles.preloader} style={{height: "50px"}}>
                            <Preloader/>
                        </div>
                    )}

                    {contextValue.filterCards.length === 0 && (
                        <div className={styles.caution}>
                            Ничего не найдено <br/> Смените параметры фильтрации
                        </div>
                    )}
                </div>
            )}
        </section>
    )
}

export default Gallery
