import { useEffect, useState } from 'react';
import { fullDate } from '../../utils/Monthhelpers';
import ArrowDown from '../../images/arrow-down-green.svg';
import ArrowUp from '../../images/arrow-up-green.svg';
import { useMediaQuery } from 'react-responsive';
import Elipse from '../../images/Ellipse480.png';
import Attention from '../../images/Attention-yellow.svg';
import Preloader from '../preloader/preloader';
import styles from './topFifteen.module.css';
import {useTheme} from "../../services/hooks/useTheme";

const MainSectionTopFifteen = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(false);

  const fetchData = async () => {
    try {
      setLoading(true);
      const username = 'investera_admin';
      const password = 'FOPoJ53hYWmmV3WuaAPj';
      const authString = btoa(`${username}:${password}`);
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Basic ${authString}`,
      };
      const res = await fetch(
        'https://stocks.invest-era.ru/api/get-top?country=RUS',
        {
          method: 'GET',
          headers,
        }
      );
      if (!res.ok) {
        setErr(true);
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const dataJson = await res.json();
      setData(dataJson);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
      setErr(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={err ? { display: 'none' } : null} className={styles.topFifteen}>
      {loading || data.length === 0 ? (
        <div className={styles.preloader}>
          <Preloader />
        </div>
      ) : (
        <>
          <div className={styles.topFifteenContainer}>
            <ViewFirstSecodnEshelon data={data} />
          </div>
          <div className={styles.topFifteenContainer}>
            <ViewThirdEshelon data={data} />
          </div>
        </>
      )}
    </div>
  );
};

export default MainSectionTopFifteen;

const ViewFirstSecodnEshelon = ({ data }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const isMobileSmall = useMediaQuery({ query: '(max-width: 760px)' });
  const [showMore, setShowMore] = useState(false);
  const [limit, setLimit] = useState(5);
  const {theme} = useTheme();
  const [mobileShowDecrease, setMobileShowDecrease] = useState(false);
  const [mobileShowIncrease, setMobileShowIncrease] = useState(false);
  const [mobileLimitDecrease, setMobileLimitDecrease] = useState(5);
  const [mobileLimitIncrease, setMobileLimitIncrease] = useState(5);
  const dataDecrease = data?.['1,2 эшелоны']?.['top_decrease']?.slice(
    0,
    isMobile ? mobileLimitDecrease : limit
  );
  const dataIncrease = data?.['1,2 эшелоны']?.['top_increase']?.slice(
    0,
    isMobile ? mobileLimitIncrease : limit
  );
  const exchangeClose = Boolean(data?.message);

  const showMoreItems = () => {
    setShowMore(true);
    setLimit(limit + 5);
  };
  const closedMoreItems = () => {
    setShowMore(false);
    setLimit(5);
  };
  const showMoreItemsMobileDecrease = () => {
    setMobileShowDecrease(true);
    setMobileLimitDecrease(mobileLimitDecrease + 5);
  };
  const showMoreItemsMobileIncrease = () => {
    setMobileShowIncrease(true);
    setMobileLimitIncrease(mobileLimitIncrease + 5);
  };
  const closedMoreItemsMobileDecrease = () => {
    setMobileShowDecrease(false);
    setMobileLimitDecrease(5);
  };
  const closedMoreItemsMobileIncrease = () => {
    setMobileShowIncrease(false);
    setMobileLimitIncrease(5);
  };

  const svgArrColor = () => {
    const color = theme === 'app_light_theme' ? '#047337' : '#37D881';
    if(showMore) {
      return (
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.5 14L12.5 10L8.5 14" stroke={color} strokeWidth="2"/>
          </svg>
      )
    } else {
      return (
          <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.5 10L12.5 14L16.5 10" stroke={color} strokeWidth="2"/>
          </svg>
      )
    }

  }

  return !isMobile ? (
      <>
        <div className={styles.flexContainer}>
          <div className={styles.elipse}>
            <img className={styles.elipseImg} src={Elipse} alt="elipse" />
        </div>
        <div className={styles.container}>
          <h2 className={`${styles.title} ${styles.green}`}>Топ-10</h2>
          <h3 className={styles.subTitle}>
            Растущих акций за {fullDate(data)}
          </h3>
          <h4 className={styles.descrTitle}>1-2 эшелоны</h4>
          <div
            style={exchangeClose ? { display: 'blok' } : { display: 'none' }}
            className={styles.exchangeClose}
          >
            <img className={styles.exchangeCloseImg} src={Attention} alt="!" />
            На момент закрытия биржи
          </div>
          <div className={styles.wrapper}>
            <div className={styles.wrapperTitle}>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleStocks}`}
              >
                Акции
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titlePrice}`}
              >
                Цена
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleChange}`}
              >
                Изменение
              </div>
            </div>
            {dataDecrease &&
              dataDecrease?.map((el, index) => (
                <div className={styles.wrapperItem} key={el.place}>
                  <div className={styles.itemName}>
                    <div className={styles.logo}>{el?.place}</div>
                    <div className={styles.wrapperNameCompany}>
                      <div className={styles.companyName}>
                        {el?.company_name}
                      </div>
                      <div className={styles.companyTicker}>{el?.ticker}</div>
                    </div>
                  </div>
                  <div className={styles.price}>
                    {el?.current_price.toFixed(2)} ₽
                  </div>
                  <div className={`${styles.change} ${styles.green}`}>
                    {el?.price_change_pct.toFixed(2)}%{' '}
                    <span className={styles.sign}>▲</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.container}>
          <h2 className={`${styles.title} ${styles.red}`}>Топ-10</h2>
          <h3 className={styles.subTitle}>
            Падающих акций за {fullDate(data)}
          </h3>
          <h4 className={styles.descrTitle}>1-2 эшелоны</h4>
          <div
            style={exchangeClose ? { display: 'blok' } : { display: 'none' }}
            className={styles.exchangeClose}
          >
            <img className={styles.exchangeCloseImg} src={Attention} alt="!" />
            На момент закрытия биржи
          </div>
          <div className={styles.wrapper}>
            <div className={styles.wrapperTitle}>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleStocks}`}
              >
                Акции
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titlePrice}`}
              >
                Цена
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleChange}`}
              >
                Изменение
              </div>
            </div>
            {dataIncrease &&
              dataIncrease?.map((el, index) => (
                <div className={styles.wrapperItem} key={el.place}>
                  <div className={styles.itemName}>
                    <div className={styles.logo}>{el?.place}</div>
                    <div className={styles.wrapperNameCompany}>
                      <div className={styles.companyName}>
                        {el?.company_name}
                      </div>
                      <div className={styles.companyTicker}>{el?.ticker}</div>
                    </div>
                  </div>
                  <div className={styles.price}>
                    {el?.current_price.toFixed(2)} ₽
                  </div>
                  <div className={`${styles.change} ${styles.red}`}>
                    {el?.price_change_pct.toFixed(2)}%{' '}
                    <span className={styles.sign}>▼</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <button
        onClick={showMore ? closedMoreItems : showMoreItems}
        className={styles.showMoreButton}
      >
        {showMore ? 'Свернуть топ' : 'Развернуть топ'}
        {svgArrColor()}
      </button>
      <div className={`${styles.updateText} ${styles.firstSecondtext}`}>
        Обновлено {fullDate(data)}
      </div>
    </>
  ) : (
    <>
      <div className={styles.flexContainer}>
        <div className={styles.elipse}>
          <img className={styles.elipseImg} src={Elipse} alt="elipse" />
        </div>
        <div className={styles.container}>
          <h2 className={`${styles.title} ${styles.green}`}>Топ-10</h2>
          <h3 className={styles.subTitle}>
            Растущих акций за {fullDate(data)}
          </h3>
          <h4 className={styles.descrTitle}>1-2 эшелоны</h4>
          <div
            style={exchangeClose ? { display: 'blok' } : { display: 'none' }}
            className={styles.exchangeClose}
          >
            <img className={styles.exchangeCloseImg} src={Attention} alt="!" />
            На момент закрытия биржи
          </div>
          <div className={styles.wrapper}>
            <div className={styles.wrapperTitle}>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleStocks}`}
              >
                Акции
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titlePrice}`}
              >
                Цена
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleChange}`}
              >
                Изменение
              </div>
            </div>
            {dataDecrease &&
              dataDecrease?.map((el, index) => (
                <div className={styles.wrapperItem} key={el.place}>
                  <div className={styles.itemName}>
                    <div className={styles.logo}>{el?.place}</div>
                    <div className={styles.wrapperNameCompany}>
                      <div className={styles.companyName}>
                        {el?.company_name}
                      </div>
                      <div className={styles.companyTicker}>{el?.ticker}</div>
                    </div>
                  </div>
                  {!isMobileSmall ? (
                    <>
                      <div className={styles.price}>
                        {el?.current_price.toFixed(2)} ₽
                      </div>
                      <div className={`${styles.change} ${styles.green}`}>
                        {el?.price_change_pct.toFixed(2)}%{' '}
                        <span className={styles.sign}>▲</span>
                      </div>
                    </>
                  ) : (
                    <div className={styles.mobilePriceWrapper}>
                      <div className={styles.price}>
                        {el?.current_price.toFixed(2)} ₽
                      </div>
                      <div className={`${styles.change} ${styles.green}`}>
                        {el?.price_change_pct.toFixed(2)}%{' '}
                        <span className={styles.sign}>▲</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}

            <button
              onClick={
                mobileShowDecrease
                  ? closedMoreItemsMobileDecrease
                  : showMoreItemsMobileDecrease
              }
              className={styles.showMoreButton}
            >
              {mobileShowDecrease ? 'Свернуть' : 'Еще'}
              {svgArrColor()}
            </button>
            <div className={styles.updateText}>Обновлено {fullDate(data)}</div>
          </div>
        </div>
        <div
          className={`${styles.container} ${showMore ? styles.expanded : ''}`}
        >
          <h2 className={`${styles.title} ${styles.red}`}>Топ-10</h2>
          <h3 className={styles.subTitle}>
            Падающих акций за {fullDate(data)}
          </h3>
          <h4 className={styles.descrTitle}>1-2 эшелоны</h4>
          <div
            style={exchangeClose ? { display: 'blok' } : { display: 'none' }}
            className={styles.exchangeClose}
          >
            <img className={styles.exchangeCloseImg} src={Attention} alt="!" />
            На момент закрытия биржи
          </div>
          <div className={styles.wrapper}>
            <div className={styles.wrapperTitle}>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleStocks}`}
              >
                Акции
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titlePrice}`}
              >
                Цена
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleChange}`}
              >
                Изменение
              </div>
            </div>
            {dataIncrease &&
              dataIncrease?.map((el, index) => (
                <div className={styles.wrapperItem} key={el.place}>
                  <div className={styles.itemName}>
                    <div className={styles.logo}>{el?.place}</div>
                    <div className={styles.wrapperNameCompany}>
                      <div className={styles.companyName}>
                        {el?.company_name}
                      </div>
                      <div className={styles.companyTicker}>{el?.ticker}</div>
                    </div>
                  </div>
                  {!isMobileSmall ? (
                    <>
                      <div className={styles.price}>
                        {el?.current_price.toFixed(2)} ₽
                      </div>
                      <div className={`${styles.change} ${styles.red}`}>
                        {el?.price_change_pct.toFixed(2)}%{' '}
                        <span className={styles.sign}>▼</span>
                      </div>
                    </>
                  ) : (
                    <div className={styles.mobilePriceWrapper}>
                      <div className={styles.price}>
                        {el?.current_price.toFixed(2)} ₽
                      </div>
                      <div className={`${styles.change} ${styles.red}`}>
                        {el?.price_change_pct.toFixed(2)}%{' '}
                        <span className={styles.sign}>▼</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            <button
              onClick={
                mobileShowIncrease
                  ? closedMoreItemsMobileIncrease
                  : showMoreItemsMobileIncrease
              }
              className={styles.showMoreButton}
            >
              {mobileShowIncrease ? 'Свернуть' : 'Еще'}
              {svgArrColor()}
            </button>
            <div className={styles.updateText}>Обновлено {fullDate(data)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

const ViewThirdEshelon = ({ data }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const isMobileSmall = useMediaQuery({ query: '(max-width: 760px)' });
  const [limit, setLimit] = useState(5);

  const dataDecrease = data?.['3 эшелон']?.['top_decrease'];
  const dataIncrease = data?.['3 эшелон']?.['top_increase'];
  const exchangeClose = Boolean(data?.message);

  return !isMobile ? (
    <>
      <div className={styles.flexContainer}>
        <div className={styles.elipse}>
          <img className={styles.elipseImg} src={Elipse} alt="elipse" />
        </div>
        <div className={styles.container}>
          <h2 className={`${styles.title} ${styles.green}`}>Топ-{limit}</h2>
          <h3 className={styles.subTitle}>
            Растущих акций за {fullDate(data)}
          </h3>
          <h4 className={styles.descrTitle}>3 эшелон</h4>
          <div
            style={exchangeClose ? { display: 'blok' } : { display: 'none' }}
            className={styles.exchangeClose}
          >
            <img className={styles.exchangeCloseImg} src={Attention} alt="!" />
            На момент закрытия биржи
          </div>
          <div className={styles.wrapper}>
            <div className={styles.wrapperTitle}>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleStocks}`}
              >
                Акции
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titlePrice}`}
              >
                Цена
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleChange}`}
              >
                Изменение
              </div>
            </div>
            {dataDecrease &&
              dataDecrease?.map((el, index) => (
                <div className={styles.wrapperItem} key={el.place}>
                  <div className={styles.itemName}>
                    <div className={styles.logo}>{el?.place}</div>
                    <div className={styles.wrapperNameCompany}>
                      <div className={styles.companyName}>
                        {el?.company_name}
                      </div>
                      <div className={styles.companyTicker}>{el?.ticker}</div>
                    </div>
                  </div>
                  <div className={styles.price}>
                    {el?.current_price.toFixed(2)} ₽
                  </div>
                  <div className={`${styles.change} ${styles.green}`}>
                    {el?.price_change_pct.toFixed(2)}%{' '}
                    <span className={styles.sign}>▲</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.container}>
          <h2 className={`${styles.title} ${styles.red}`}>Топ-{limit}</h2>
          <h3 className={styles.subTitle}>
            Падающих акций за {fullDate(data)}
          </h3>
          <h4 className={styles.descrTitle}>3 эшелон</h4>
          <div
            style={exchangeClose ? { display: 'blok' } : { display: 'none' }}
            className={styles.exchangeClose}
          >
            <img className={styles.exchangeCloseImg} src={Attention} alt="!" />
            На момент закрытия биржи
          </div>
          <div className={styles.wrapper}>
            <div className={styles.wrapperTitle}>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleStocks}`}
              >
                Акции
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titlePrice}`}
              >
                Цена
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleChange}`}
              >
                Изменение
              </div>
            </div>
            {dataIncrease &&
              dataIncrease?.map((el, index) => (
                <div className={styles.wrapperItem} key={el.place}>
                  <div className={styles.itemName}>
                    <div className={styles.logo}>{el?.place}</div>
                    <div className={styles.wrapperNameCompany}>
                      <div className={styles.companyName}>
                        {el?.company_name}
                      </div>
                      <div className={styles.companyTicker}>{el?.ticker}</div>
                    </div>
                  </div>
                  <div className={styles.price}>
                    {el?.current_price.toFixed(2)} ₽
                  </div>
                  <div className={`${styles.change} ${styles.red}`}>
                    {el?.price_change_pct.toFixed(2)}%{' '}
                    <span className={styles.sign}>▼</span>
                  </div>
                </div>
              ))}

            <div className={styles.updateText}>Обновлено {fullDate(data)}</div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className={styles.flexContainer}>
        <div className={styles.elipse}>
          <img className={styles.elipseImg} src={Elipse} alt="elipse" />
        </div>
        <div className={styles.container}>
          <h2 className={`${styles.title} ${styles.green}`}>Топ-{limit}</h2>
          <h3 className={styles.subTitle}>
            Растущих акций за {fullDate(data)}
          </h3>
          <h4 className={styles.descrTitle}>3 эшелон</h4>
          <div
            style={exchangeClose ? { display: 'blok' } : { display: 'none' }}
            className={styles.exchangeClose}
          >
            <img className={styles.exchangeCloseImg} src={Attention} alt="!" />
            На момент закрытия биржи
          </div>
          <div className={styles.wrapper}>
            <div className={styles.wrapperTitle}>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleStocks}`}
              >
                Акции
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titlePrice}`}
              >
                Цена
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleChange}`}
              >
                Изменение
              </div>
            </div>
            {dataDecrease &&
              dataDecrease?.map((el, index) => (
                <div className={styles.wrapperItem} key={el.place}>
                  <div className={styles.itemName}>
                    <div className={styles.logo}>{el?.place}</div>
                    <div className={styles.wrapperNameCompany}>
                      <div className={styles.companyName}>
                        {el?.company_name}
                      </div>
                      <div className={styles.companyTicker}>{el?.ticker}</div>
                    </div>
                  </div>
                  {!isMobileSmall ? (
                    <>
                      <div className={styles.price}>
                        {el?.current_price.toFixed(2)} ₽
                      </div>
                      <div className={`${styles.change} ${styles.green}`}>
                        {el?.price_change_pct.toFixed(2)}%{' '}
                        <span className={styles.sign}>▲</span>
                      </div>
                    </>
                  ) : (
                    <div className={styles.mobilePriceWrapper}>
                      <div className={styles.price}>
                        {el?.current_price.toFixed(2)} ₽
                      </div>
                      <div className={`${styles.change} ${styles.green}`}>
                        {el?.price_change_pct.toFixed(2)}%{' '}
                        <span className={styles.sign}>▲</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            <div className={styles.updateText}>Обновлено {fullDate(data)}</div>
          </div>
        </div>
        <div className={styles.container}>
          <h2 className={`${styles.title} ${styles.red}`}>Топ-{limit}</h2>
          <h3 className={styles.subTitle}>
            Падающих акций за {fullDate(data)}
          </h3>
          <h4 className={styles.descrTitle}>3 эшелон</h4>
          <div
            style={exchangeClose ? { display: 'blok' } : { display: 'none' }}
            className={styles.exchangeClose}
          >
            <img className={styles.exchangeCloseImg} src={Attention} alt="!" />
            На момент закрытия биржи
          </div>
          <div className={styles.wrapper}>
            <div className={styles.wrapperTitle}>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleStocks}`}
              >
                Акции
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titlePrice}`}
              >
                Цена
              </div>
              <div
                className={`${styles.wrapperTitleName} ${styles.titleChange}`}
              >
                Изменение
              </div>
            </div>
            {dataIncrease &&
              dataIncrease?.map((el, index) => (
                <div className={styles.wrapperItem} key={el.place}>
                  <div className={styles.itemName}>
                    <div className={styles.logo}>{el?.place}</div>
                    <div className={styles.wrapperNameCompany}>
                      <div className={styles.companyName}>
                        {el?.company_name}
                      </div>
                      <div className={styles.companyTicker}>{el?.ticker}</div>
                    </div>
                  </div>
                  {!isMobileSmall ? (
                    <>
                      <div className={styles.price}>
                        {el?.current_price.toFixed(2)} ₽
                      </div>
                      <div className={`${styles.change} ${styles.red}`}>
                        {el?.price_change_pct.toFixed(2)}%{' '}
                        <span className={styles.sign}>▼</span>
                      </div>
                    </>
                  ) : (
                    <div className={styles.mobilePriceWrapper}>
                      <div className={styles.price}>
                        {el?.current_price.toFixed(2)} ₽
                      </div>
                      <div className={`${styles.change} ${styles.red}`}>
                        {el?.price_change_pct.toFixed(2)}%{' '}
                        <span className={styles.sign}>▼</span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            <div className={styles.updateText}>Обновлено {fullDate(data)}</div>
          </div>
        </div>
      </div>
    </>
  );
};
