import React, {useEffect, useState} from "react";
import {FinanceBarChart} from "../screener-tab-finance/finance-bar-chart/finance-bar-chart";
import {FinanceTable} from "../screener-tab-finance/finance-table/finance-table";
import {getFinanceDataForScreener} from "../../../../../utils/api";
import styles from "./screener-tab-predictions.module.css";
import {fillObjectFromArr, transformObject} from "../screener-tab-finance/utilsForFinanceTab";
import Preloader from "../../../../preloader/preloader";

export const ScreenerTabPredictions = ({information}) => {
    const [data, setData] = useState({})
    const [selectedPredictRows, setSelectedPredictRows] = useState(['netIncome', 'ebitda']);
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const getFinanceDataForBlock = (ticker, keyBlock) => {
        // getFinanceDataForScreener(ticker, period).then(async (res) => {
        //     const setFunc = setSelectedCompany(keyBlock)
        //     setFunc(res)
        // })
        //     .catch((e) => {
        //         console.log(e)
        //     });
    }

    const handleLegendClick = (record) => {
        // const setSelectedFunc = selectedRowsForMapping(keyBlock, true)
        // setSelectedFunc((prevSelectedRows) => {
        //     return prevSelectedRows.filter(key => key !== record);
        // })
        //
    };

    useEffect(() => {
        setIsLoading(true)
        getFinanceDataForScreener(information.main_info.ticker, 'Y').then(async (res) => {
            let reorderedObj
            if (res.data.predictions_2024 || res.data.predictions_2025) {
                reorderedObj = {
                    predictions_2024: res.data.predictions_2024,
                    predictions_2025: res.data.predictions_2025
                };
                setData(reorderedObj)
            } else {
                setError('Прогнозы по данной компании отсутствуют')
            }

            setIsLoading(false)
        })
            .catch((e) => {
                console.log(e)
                setError('Прогнозы по данной компании отсутствуют')
                setIsLoading(false)
            });

    }, [information]);

    return (
        <div className={styles.chartBlock}>
            <div className={styles.titleContainer}>
                <h3 className={styles.blockTitle}>Прогнозы</h3>
            </div>
            {!isLoading ?
                <>{!error ? <>
                    {/*<FinanceBarChart tableRowTitles={tableRowTitles}*/}
                    {/*                 selectedRows={selectedRowsForMapping(keyBlock)}*/}
                    {/*                 dataBlock={value} period={period}*/}
                    {/*                 ticker={information.main_info?.ticker}*/}
                    {/*                 dataForCompare={selectedCompany(keyBlock)}*/}
                    {/*                 tableRowTitlesSecond={tableRowTitlesSecond}*/}
                    {/*                 selectedCompany={selectedCompany(keyBlock, true)}*/}
                    {/*                 handleLegendClick={handleLegendClick}*/}
                    {/*/>*/}
                    <FinanceTable selectedRows={selectedPredictRows}
                                  setSelectedRows={setSelectedPredictRows}
                                  dataBlock={data} keyBlock={'predictions'}
                    />
                </>
                :
                <div>{error}</div>}</>
                :
                <Preloader />
            }
        </div>
    );
};