import styles from "./subscription-price.module.css"
import RedGradient from "../../../../../../../images/Ellipse-products-red-price-section.svg"
import WhiteGradient from "../../../../../../../images/Ellipse-products-white-price-section.svg"
import { useMediaQuery } from "react-responsive"
import { useProfile } from "../../../../../../../services/context/ProfileContext"
import { useEffect } from "react"
import { NavLink } from "react-router-dom"
const SubscriptionPrice = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" })
  const contextValue = useProfile()

  useEffect(() => {
    contextValue.receptData("Ideas-All")
  }, [])


  return (
    <section className={styles.section} id="PriceSection">
      <div className={styles.backgroundWrapper}>
        <img src={RedGradient} className={styles.backgroundRed} />
        <img src={WhiteGradient} className={styles.backgroundWhite} />
      </div>
      {contextValue.receptInfo && (
        <div className={styles.containerWrapper}>
          <div className={styles.container}>
            <div className={styles.title}>
              Подписка <b>Ideas All</b>
            </div>
            <div className={styles.priceSectionWrapper}>
              <div className={styles.monthWrapper}>
                {/*
              <div className={styles.monthBox}>
                <div className={styles.monthTxt}>1 месяц</div>
                <div className={styles.priceWrapper}>
                  <div className={styles.price}>10 000₽</div>
                </div>
              </div>
              */}

                {contextValue.receptInfo.map((el, i, arr) => (
                  <div className={styles.monthBox} key={i}>
                    <div className={styles.monthTxt}>
                      {`${el.month}`} {el.month > 1 ? "месяцев" : "месяц"}
                    </div>
                    <div className={styles.priceWrapper}>
                      {i !== 0 && (
                        <div className={styles.subDescription}>
                          Экономия {el.discount}%
                        </div>
                      )}
                      <div className={styles.price}>
                        {`${el.roubles}₽`}
                        {i !== 0 && (
                          <div className={styles.crossPrice}>
                            {arr[0].roubles * el.month}₽
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
                {/*

              <div className={styles.monthBox}>
                <div className={styles.monthTxt}>6 месяцев</div>
                <div className={styles.priceWrapper}>
                  <div className={styles.subDescription}>Экономия 10%</div>
                  <div className={styles.price}>
                    50 000₽
                    <div className={styles.crossPrice}>60 000₽</div>
                  </div>
                </div>
              </div>
              */}

                {/*
              <div className={styles.monthBox}>
                <div className={styles.monthTxt}>12 месяцев</div>
                <div className={styles.priceWrapper}>
                  <div className={styles.subDescription}>Экономия 25%</div>
                  <div className={styles.price}>
                    90 000₽
                    <div className={styles.crossPrice}>120 000₽</div>
                  </div>
                </div>
              </div>
            */}
              </div>
              <NavLink to={"/profile"} className={styles.buttonWrapper}>
                Перейти к оплате
              </NavLink>
            </div>
            <div className={styles.subTitle}>
              Если вам не понравится - вернем деньги!
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default SubscriptionPrice
