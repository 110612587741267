import React from "react"
import { useContext, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { getInfoMainPageData, urlDataPageInfo } from "../../utils/api"
import { useActiveButtonCountryTagChoice } from "./CountryFilterContext"

const PageInfoContext = React.createContext()

export const usePageInfoContext = () => {
  return useContext(PageInfoContext)
}

export const PageInfoProvider = () => {
  const [firstData, setFirstData] = useState(false)
  const [urlPageData, setUrlPageData] = useState(false)
  const navigate = useNavigate()
  const val = useActiveButtonCountryTagChoice()

  const infoDataFromApi = () => {
    getInfoMainPageData().then((res) => {
      setFirstData(res)
    })
  }

  const pageFromSlugUrl = (slug) => {
    urlDataPageInfo(slug).then(async (res) => {
      const path = {
        404: "/not-found",
        // 401: "/authorization",
        403: "/error-403",
        500: "/error-500",
      }
      if (res.ok) {
        const data = await res.json()
        setUrlPageData(data)
      } else if (res.status in path) {
        val.setBackendErrorCheck(true)
        navigate(path[res.status])
      }
      // else {
      //   return res.json()
      // }
    })
    // .then((res) => {
    //   if (!!res) {
    //     console.log(res.detail)
    //   }
    //   if (!!res && res.detail === "Недопустимый токен.") {
    //     logout() // Включить для выброса!!!!!!!!!!!!!!!!!
    //   }
    // })
  }

  return (
    <PageInfoContext.Provider
      value={{
        infoDataFromApi,
        firstData,
        pageFromSlugUrl,
        urlPageData,
      }}
    >
      <Outlet />
    </PageInfoContext.Provider>
  )
}
