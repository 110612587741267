import React, {useState} from 'react';
import YouTube from 'react-youtube';
import styles from "./youtube-video.module.css";
import {useResize} from "../../../../../services/hooks/useResize";

const YouTubePlayer = ({ videoId, title, description }) => {
    const [player, setPlayer] = useState(null);
    const screenWidth = useResize()

/*    const [isExpanded, setIsExpanded] = useState(false);


    const seekTo = (seconds) => {
        if (player) {
            player.seekTo(seconds);
            player.playVideo();
        }
    };

    const toggleList = () => {
        setIsExpanded(!isExpanded);
    };*/

    const onReady = (event) => {
        setPlayer(event.target);
    };

    const videoOptions = {

        playerVars: {
            autoplay: 0,
        },
    };
//
    return (
        <div>
            <div className={styles.videoWrapper}>
            <YouTube
                videoId={videoId}
                opts={videoOptions}
                onReady={onReady}
            />
            </div>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>
                {description}
            </div>
{/*            <ul className={styles.timeCodes}>
                <li>
                    <button onClick={() => seekTo(10)} className={styles.timeCode}>
                        <span className={styles.time}>0:10</span> - Тайм-код 1
                    </button>
                </li>
                <li>
                    <button onClick={() => seekTo(60)} className={styles.timeCode}>
                        <span className={styles.time}>1:00</span> - Тайм-код 2
                    </button>
                </li>
                {isExpanded && (
                    <>
                        <li>
                            <button onClick={() => seekTo(120)} className={styles.timeCode}>
                                <span className={styles.time}>2:00</span> - Тайм-код 3
                            </button>
                        </li>
                        <li>
                            <button onClick={() => seekTo(180)} className={styles.timeCode}>
                                <span className={styles.time}>3:00</span> - Тайм-код 4
                            </button>
                        </li>
                        <li>
                            <button onClick={() => seekTo(240)} className={styles.timeCode}>
                                <span className={styles.time}>4:00</span> - Тайм-код 5
                            </button>
                        </li>
                    </>
                )}
            </ul>
            <button onClick={toggleList} className={styles.toggleButton}>
                {isExpanded ? 'Скрыть' : 'Показать все'}
            </button>*/}
        </div>
    );
};

export default YouTubePlayer;