import React, {useState} from 'react';
import {PriceChart} from "../../../../price-chart/price-chart";
import {LineChartIcon} from "../../../../../images/components/line-chart";
import {CandleChartIcon} from "../../../../../images/components/candle-chart";
import {BarChartIcon} from "../../../../../images/components/bar-chart";
import Preloader from "../../../../preloader/preloader";
import styles from './screener-tab-analysis.module.css'

const ScreenerTabAnalysis = ({information}) => {
    const [activeButton, setActiveButton] = useState('candle')
    const [period, setPeriod] = useState('1w');
    const [isLoading, setIsLoading] = useState(false)

    const changeChartStyle = (style) => {
        setActiveButton(style)
    }

    const changeChartPeriod = (period) => {
        setPeriod(period)
    }

    const styleButtons = [
        {id: 11, title: 'candle', img: <CandleChartIcon/>},
        {id: 22, title: 'line', img: <LineChartIcon/>},
        {id: 33, title: 'bar', img: <BarChartIcon/>}
    ]

    const periodButtons = [
        {id: 1, title: '1d', name: '1д', tooltip: 'Сутки в 1-минутных интервалах'},
        {id: 2, title: '1w', name: '1нед', tooltip: 'Неделя в 1-часовых интервалах'},
        {id: 3, title: '1m', name: '1мес', tooltip: 'Месяц в  1-часовых интервалах'},
        {id: 4, title: '3m', name: '3мес', tooltip: '3 месяца в 1-дневных интервалах'},
        {id: 5, title: '6m', name: '6мес', tooltip: '6 месяцев в 1-дневных интервалах'},
        {id: 6, title: '1y', name: '1год', tooltip: '1 год в 1-дневных интервалах'},
        {id: 7, title: '5y', name: '5лет', tooltip: '5 лет в недельных интервалах'},
        {id: 8, title: 'all', name: 'Все', tooltip: 'В недельных интервалах'},
    ]

    return (
        <div>
            <div className={styles.chartContainer}>
                <h2 className={styles.header}>График</h2>

                <div className={styles.buttonsContainer}>
                    <div className={styles.typeButtonsContainer}>
                        {styleButtons.map(el => {
                            return <button key={el.id}
                                           className={`${styles.button} ${activeButton === el.title && styles.activeButton}`}
                                           onClick={() => changeChartStyle(el.title)}>
                                {el.img}
                            </button>
                        })}
                    </div>

                    <div className={styles.typeButtonsContainer}>
                        {periodButtons.map(el => {
                            return <button key={el.id}
                                           className={`${styles.button} ${period === el.title && styles.activeButton}`}
                                           onClick={() => changeChartPeriod(el.title)}>
                                {el.name}
                                <span className={styles.tooltipText}>{el.tooltip}</span>
                            </button>
                        })}
                    </div>
                </div>

                {isLoading && <Preloader/>}
                <PriceChart information={information} activeButton={activeButton} period={period}
                            setIsLoading={setIsLoading}/>

            </div>
        </div>
    );
};

export default ScreenerTabAnalysis;