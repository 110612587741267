import React from "react";
import checkmarkIcon from "../../../../../images/Checkmark.svg";
import styles from "./finance-table/finance-table.module.css";

// Функция для сравнения показателей двух компаний
export const processData = (data) => {
    return data.map((item) => {
        const processedItem = {...item};

        // Compute revenue
        if (item.revenue > item.revenueCompare) {
            processedItem.revenue -= item.revenueCompare;
        } else {
            processedItem.revenueCompare -= item.revenue;
        }

        // Compute clearProfit
        if (item.clearProfit > item.clearProfitCompare) {
            processedItem.clearProfit -= item.clearProfitCompare;
        } else {
            processedItem.clearProfitCompare -= item.clearProfit;
        }

        // Compute cost
        if (item.cost > item.costCompare) {
            processedItem.cost -= item.costCompare;
        } else {
            processedItem.costCompare -= item.cost;
        }

        // Compute gross
        if (item.gross > item.grossCompare) {
            processedItem.gross -= item.grossCompare;
        } else {
            processedItem.grossCompare -= item.gross;
        }

        // Compute operatingProfit
        if (item.operatingProfit > item.operatingProfitCompare) {
            processedItem.operatingProfit -= item.operatingProfitCompare;
        } else {
            processedItem.operatingProfitCompare -= item.operatingProfit;
        }

        // Compute operatingExp
        if (item.operatingExp > item.operatingExpCompare) {
            processedItem.operatingExp -= item.operatingExpCompare;
        } else {
            processedItem.operatingExpCompare -= item.operatingExp;
        }

        // Compute researchDev
        if (item.researchDev > item.researchDevCompare) {
            processedItem.researchDev -= item.researchDevCompare;
        } else {
            processedItem.researchDevCompare -= item.researchDev;
        }

        // Compute adminExp
        if (item.adminExp > item.adminExpCompare) {
            processedItem.adminExp -= item.adminExpCompare;
        } else {
            processedItem.adminExpCompare -= item.adminExp;
        }

        return processedItem;
    });
};

// Функция преобразования заголовков показателей, блоков и подблоков
export const indicatorValue = (value) => {
    // Заголовки блоков
    if (value === 'incomeStatement') return 'Доходы'
    if (value === 'balanceSheet') return 'Бухгалтерский баланс'
    if (value === 'cashFlow') return 'Движение денежных средств'
    if (value === 'ratios') return 'Мультипликаторы'

    // Заголовки подблоков
    if (value === 'Revenue') return 'Выручка'
    if (value === 'OperationIncomeAndExpenses') return 'Операционные доходы и расходы'
    if (value === 'EarningsFromContOperations') return 'Доходы от непрерывных операций'
    if (value === 'NetIncome') return 'Чистый доход'
    if (value === 'Supplemental') return 'Дополнительно'
    if (value === 'LongTermAssets') return 'Долгосрочные активы'
    if (value === 'CashFlowFromOpetaions') return 'Денежный поток от операций'
    if (value === 'KeyStats') return 'Ключевые мультипликаторы'

    // Заголовки показателей
    // Доходы
    if (value === 'totalRevenue' || value === 'totalRevenueCompare') return 'Итоговая выручка'
    if (value === 'costOfRevenue' || value === 'costOfRevenueCompare') return 'Себестоимость'
    if (value === 'grossProfit' || value === 'grossProfitCompare') return 'Валовая прибыль'
    if (value === 'operatingIncome' || value === 'operatingIncomeCompare') return 'Операционная прибыль'
    if (value === 'totalOperatingExpenses' || value === 'totalOperatingExpensesCompare') return 'Общие операционные расходы'
    if (value === 'researchDevelopment' || value === 'researchDevelopmentCompare') return 'Расходы на исследования'
    if (value === 'sellingGeneralAdministrative' || value === 'sellingGeneralAdministrativeCompare') return 'Административные расходы'
    if (value === 'interestExpense' || value === 'interestExpenseCompare') return 'Расходы на обслуживание долга'
    if (value === 'interestIncome' || value === 'interestIncomeCompare') return 'Доход от процентов'
    if (value === 'netIncome' || value === 'netIncomeCompare') return 'Чистая прибыль'
    if (value === 'ebitda' || value === 'ebitdaCompare') return 'EBITDA'
    if (value === 'oibda' || value === 'ebitdaCompare') return 'OIBDA'
    if (value === 'grossMargin' || value === 'grossMarginCompare') return 'Валовая маржа, %'
    if (value === 'netMargin' || value === 'netMarginCompare') return 'Маржа по чистой прибыли, %'
    if (value === 'ebitdaMargin' || value === 'ebitdaMarginCompare') return 'Маржа по EBITDA, %'
    if (value === 'oibdaMargin' || value === 'ebitdaMarginCompare') return 'Маржа по OIBDA, %'
    if (value === 'operatingMargin' || value === 'operatingMarginCompare') return 'Операционная маржа, %'
    if (value === 'incomeTaxExpense' || value === 'incomeTaxExpenseCompare') return 'Расходы на налог'

    // Бухгалтерский баланс
    // if (value === 'totalAssets' || value === 'totalAssetsCompare') return 'Размер активов'
    // if (value === 'commonStockSharesOutstanding' || value === 'commonStockSharesOutstandingCompare') return 'Количество обыкновенных акций'
    if (value === 'cashAndEquivalents' || value === 'cashAndEquivalentsCompare') return 'Денежные средства и эквиваленты'
    if (value === 'totalStockholderEquity' || value === 'totalStockholderEquityCompare') return 'Итого капитал'
    if (value === 'liabilitiesTotal' || value === 'liabilitiesTotalCompare') return 'Всего обязательств'
    if (value === 'totalDebt' || value === 'totalDebtCompare') return 'Общий долг'
    if (value === 'netDebt' || value === 'netDebtCompare') return 'Чистый долг'
    if (value === 'ev' || value === 'evCompare') return 'Стоимость компании (EV)'
    if (value === 'capitalization' || value === 'capitalizationCompare') return 'Капитализация'

    // Движение денежных средств
    if (value === 'totalCashFromOperatingActivities' || value === 'totalCashFromOperatingActivitiesCompare') return 'Операционный денежный поток'
    if (value === 'freeCashFlow' || value === 'freeCashFlowCompare') return 'Свободный денежный поток'
    if (value === 'capitalExpenditures' || value === 'capitalExpendituresCompare') return 'Капитальные затраты'

    // Мультипликаторы
    if (value === 'eps' || value === 'epsCompare') return 'EPS'
    if (value === 'pe' || value === 'peCompare') return 'P / E'
    if (value === 'payout' || value === 'payoutCompare') return 'Payout'
    if (value === 'pricetobookvalue' || value === 'pricetobookvalueCompare') return 'P / B'
    if (value === 'netdebttoebitda' || value === 'netdebttoebitdaCompare') return 'Net Debt / EBITDA'
    if (value === 'debttoequity' || value === 'debttoequityCompare') return 'D / E'
    if (value === 'evtoebitda' || value === 'evtoebitdaCompare') return 'EV / EBITDA'
    if (value === 'roa' || value === 'roaCompare') return 'ROA'
    if (value === 'roe' || value === 'roeCompare') return 'ROE'
    if (value === 'pricetorevenuepershare' || value === 'pricetorevenuepershareCompare') return 'P / S'
    if (value === 'pricetofreecashflowpershare' || value === 'pricetofreecashflowpershareCompare') return 'P / FCF'
    // if (value === 'freecashflowpershare' || value === 'freecashflowpershareCompare') return 'FCF per Share'
    // if (value === 'revenuepershare' || value === 'revenuepershareCompare') return 'Revenue per Share'

    return value
}

// Функция преобразования заголовков показателей для stackId графиков
export const stackIdValue = (value) => {
    // Заголовки блоков
    if (value === 'Revenue') return 'a'
    if (value === 'OperationIncomeAndExpenses') return 'b'
    if (value === 'EarningsFromContOperations') return 'c'
    if (value === 'NetIncome') return 'd'
    if (value === 'Supplemental') return 'e'
    if (value === 'LongTermAssets') return 'f'
    if (value === 'CashFlowFromOpetaions') return 'g'
    if (value === 'KeyStats') return 'h'

    // Заголовки подблоков
    if (value === 'totalRevenue') return 'i'
    if (value === 'costOfRevenue') return 'j'
    if (value === 'grossProfit') return 'k'
    if (value === 'operatingIncome') return 'l'
    if (value === 'totalOperatingExpenses') return 'm'
    if (value === 'researchDevelopment') return 'n'
    if (value === 'sellingGeneralAdministrative') return 'o'
    if (value === 'interestExpense') return 'p'
    if (value === 'interestIncome') return 'q'
    if (value === 'netIncome') return 'r'
    if (value === 'ebitda') return 's'
    if (value === 'grossMargin') return 'll'
    if (value === 'netMargin') return 'mm'
    if (value === 'ebitdaMargin') return 'nn'
    if (value === 'operatingMargin') return 'oo'
    if (value === 'incomeTaxExpense') return 'pp'

    /*    if (value === 'totalAssets') return 't'
        if (value === 'commonStockSharesOutstanding') return 'u'*/
    if (value === 'cashAndEquivalents') return 'qq'
    if (value === 'totalStockholderEquity') return 'rr'
    if (value === 'liabilitiesTotal') return 'ss'
    if (value === 'totalDebt') return 'v'
    if (value === 'netDebt') return 'w'
    if (value === 'ev') return 'x'

    if (value === 'totalCashFromOperatingActivities') return 'y'
    if (value === 'freeCashFlow') return 'z'
    if (value === 'capitalExpenditures') return 'tt'

    if (value === 'eps') return 'aa'
    if (value === 'pe') return 'bb'
    if (value === 'payout') return 'cc'
    // if (value === 'revenuepershare') return 'dd'
    if (value === 'pricetobookvalue') return 'ee'
    if (value === 'netdebttoebitda') return 'ff'
    if (value === 'debttoequity') return 'gg'
    if (value === 'evtoebitda') return 'hh'
    // if (value === 'freecashflowpershare') return 'ii'
    if (value === 'roa') return 'jj'
    if (value === 'roe') return 'kk'
}

// Функция обработки значений в таблицах
export const transformYearValue = (yearValue, titleKey, parameterKey) => {
    if (typeof yearValue === 'number') {

        let transformedValue;

        if (titleKey === 'payout' || titleKey === 'roa' || titleKey === 'roe') {
            transformedValue = yearValue.toFixed(2);
            return `${transformedValue.endsWith('.00') ? transformedValue.split('.')[0] : transformedValue}%`;
        } else if (yearValue < 3 && parameterKey === 'KeyStats') {
            transformedValue = yearValue.toFixed(6);
            return transformedValue.endsWith('.000000') ? transformedValue.split('.')[0] : transformedValue;
        } else if (titleKey === 'commonStockSharesOutstanding') {
            transformedValue = (yearValue * 1000).toFixed(1);
            return transformedValue.endsWith('.0') ? transformedValue.split('.')[0] : transformedValue;
        } else {
            transformedValue = yearValue.toFixed(1);
            return transformedValue.endsWith('.0') ? transformedValue.split('.')[0] : transformedValue;
        }

    } else {
        return yearValue;
    }
}

// Функция обработки значений в тултипе
export const transformKeyStatsValues = (value, titleKey) => {

    if (titleKey === 'eps' || titleKey === 'pe' || titleKey === 'payout' || titleKey === 'revenuepershare'
        || titleKey === 'pricetobookvalue' || titleKey === 'netdebttoebitda' || titleKey === 'debttoequity'
        || titleKey === 'evtoebitda' || titleKey === 'freecashflowpershare' || titleKey === 'roa' || titleKey === 'roe' ||
        titleKey === 'epsCompare' || titleKey === 'peCompare' || titleKey === 'payoutCompare' || titleKey === 'revenuepershareCompare'
        || titleKey === 'pricetobookvalueCompare' || titleKey === 'netdebttoebitdaCompare' || titleKey === 'debttoequityCompare'
        || titleKey === 'evtoebitdaCompare' || titleKey === 'freecashflowpershareCompare' || titleKey === 'roa' || titleKey === 'roeCompare'
    ) {
        const result = (value * 1000000).toFixed(1)
        return result.endsWith('.0') ? result.split('.')[0] : result

    } else {
        return value
    }
}

// Функция обработки значений для оси Y
export const axisValueFormatter = (value) => {
    if (value >= 0 && value < 1) {
        return value === 0 ? '0' : `${value * 1000000} тыс`
    }
    if (value >= 1000 && value < 1000000) return `${value / 1000} млрд`
    if (value >= 1000000) return `${value / 1000000} трлн`
    if (value <= 0 && value > -1) {
        return value === 0 ? '0' : `${value * 1000000} тыс`
    }
    if (value <= -1000 && value > -1000000) return `${value / 1000} млрд`
    if (value <= -1000000) return `${value / 1000000} трлн`
    return `${value} млн`
}

// Функция форматирования данных для графиков
export const transformData = (dataBlock, selectedRows, period) => {
    const dataMap = {};

    // Обрабатываем каждый ключ в dataBlock
    for (const key in dataBlock) {
        const entries = dataBlock[key];

        if(period === 'Q') entries.sort((a, b) => new Date(a.date) - new Date(b.date))

        entries.forEach(({parameter, date, value}) => {
            if(date !== 'average5' && date !== 'average10') {
                let year
                if(period === 'Y') {
                    year = date === 'ttm' ? 'TTM' : new Date(date).getFullYear();
                } else {
                    year = formatQuarter(date)
                }

            const valueInMillions = value ? value / 1000000 : null; // Преобразуем значение в миллионы, проверяем на null
            // Проверяем, входит ли параметр в selectedRows
            if (selectedRows.includes(parameter)) {
                // Если данных за этот год еще нет, создаем объект
                if (!dataMap[year]) {
                    dataMap[year] = {name: year.toString()};
                }

                // Добавляем значение для данного параметра
                dataMap[year][parameter] = valueInMillions;
            }
            }
        });
    }

    // Преобразуем объект в массив
    return Object.values(dataMap);
};


// Функция для формирования заголовков и цветов для строк в таблицах
export const generateTableRowTitles = (data, period) => {
    const result = [];
    const fillColors = ['#BC79FF', '#69CDFF', '#80FFEA', '#75F77D',
        '#FF66CF', '#FF5555', '#FFC94F', '#F6FF1D',
        '#6E7FFF', '#f64e60', '#8950fc', '#1bc5bd']; // Массив цветов для заполнения
    let fillIndex = 0;

    const traverse = (obj, prefix = '') => {

        Object.entries(obj).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                // Фильтруем массив, оставляя только объекты с type: 'Y' или 'Q'
                const filteredArray = value.filter(item => item.type === period);
                const finalFilteredArray = filteredArray.filter(item => item.parameter !== 'totalAssets' && item.parameter !== 'commonStockSharesOutstanding'
                    && item.parameter !== 'freecashflowpershare' && item.parameter !== 'revenuepershare' && item.parameter !== 'predictions_2025' && item.parameter !== 'predictions_2024'
                    && item.parameter !== 'payout' // ВРЕМЕННО, ПОКА НЕ ПОЯВИТСЯ БЛОК ДИВИДЕНДЫ
                );
                finalFilteredArray.forEach(item => {
                    if (item.parameter) {
                        result.push({
                            id: Math.random(), // Генерация уникального ID
                            title: prefix ? `${prefix}.${item.parameter}` : item.parameter,
                            fill: fillColors[fillIndex % fillColors.length],
                            stackId: stackIdValue(item.parameter)
                        });
                        fillIndex++;
                    }
                });
            } else if (typeof value === 'object' && value !== null) {
                traverse(value, prefix ? `${prefix}.${key}` : key);
            }
        });
    };

    traverse(data);
    return result;
};

// Функция для формирования заголовков и цветов для строк в таблицах
export const generateTableRowTitlesSecond = (data, period) => {
    const result = [];
    const fillColors = ['#7659A1', '#4D82A1', '#599B97', '#539662',
        '#974F8A', '#97804A', '#f64e608a', '#939B32',
        '#97474E', '#7e8299', '#3699ff8a', '#505BA1']; // Массив цветов для заполнения
    let fillIndex = 0;

    const traverse = (obj, prefix = '') => {
        Object.entries(obj).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                // Фильтруем массив, оставляя только объекты с type: 'Y' или 'Q'
                const filteredArray = value.filter(item => item.type === period);
                const finalFilteredArray = filteredArray.filter(item => item.parameter !== 'totalAssets' && item.parameter !== 'commonStockSharesOutstanding'
                    && item.parameter !== 'freecashflowpershare' && item.parameter !== 'revenuepershare' && item.parameter !== 'predictions_2025' && item.parameter !== 'predictions_2024'
                    && item.parameter !== 'payout' // ВРЕМЕННО, ПОКА НЕ ПОЯВИТСЯ БЛОК ДИВИДЕНДЫ
                );
                finalFilteredArray.forEach(item => {
                    if (item.parameter) {
                        result.push({
                            id: Math.random(), // Генерация уникального ID
                            title: prefix ? `${prefix}.${item.parameter}` : `${item.parameter}Compare`,
                            fill: fillColors[fillIndex % fillColors.length],
                            stackId: stackIdValue(item.parameter)
                        });
                        fillIndex++;
                    }
                });
            } else if (typeof value === 'object' && value !== null) {
                traverse(value, prefix ? `${prefix}.${key}` : key);
            }
        });
    };

    traverse(data);
    return result;
};

function formatQuarter(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear().toString().slice(-2); // Получаем последние 2 цифры года
    const month = date.getMonth() + 1; // Получаем месяц (добавляем 1, так как getMonth() возвращает значение от 0 до 11)

    let quarter;
    if (month >= 1 && month <= 3) {
        quarter = "1-й кв.";
    } else if (month >= 4 && month <= 6) {
        quarter = "2-й кв.";
    } else if (month >= 7 && month <= 9) {
        quarter = "3-й кв.";
    } else if (month >= 10 && month <= 12) {
        quarter = "4-й кв.";
    }

    return `${quarter} ${year}`;
}

// Функция формирования столбцов для таблиц
export const generateDataFromValue = (value, selectedRows, dataTest, period, values) => {
    const checkmarkImg = <img src={checkmarkIcon} alt="checkmark" width={24}/>;
    const result = []; // Итоговый массив для результатов

    // Проходим по каждому параметру в объекте dataSource (например, CashFlowFromOperations, Supplemental)
    Object.keys(value).forEach(parameterKey => {
        const dataMap = {}; // Для хранения уникальных строк с ключом rowTitle

        const dataList = value[parameterKey]; // Получаем список данных для текущего параметра
        const filteredArray = dataList.filter(item => item.type === period);
        const finalFilteredArray = filteredArray.filter(item => item.parameter !== 'totalAssets' && item.parameter !== 'commonStockSharesOutstanding'
            && item.parameter !== 'freecashflowpershare' && item.parameter !== 'revenuepershare' && item.parameter !== 'predictions_2025' && item.parameter !== 'predictions_2024'
            && item.parameter !== 'payout' // ВРЕМЕННО, ПОКА НЕ ПОЯВИТСЯ БЛОК ДИВИДЕНДЫ
        );

        if (period === 'Q') finalFilteredArray.sort((a, b) => new Date(a.date) - new Date(b.date))

        finalFilteredArray.forEach(item => {
            const titleKey = item.parameter; // Используем параметр как уникальный ключ для строки
            let year
            if (period === 'Y') {
                year = item.date === 'ttm' ? 'TTM' : item.date === 'average5' ? 'Средняя за 5 лет' : item.date === 'average10' ? 'Средняя за 10 лет' : new Date(item.date).getFullYear();
            } else {
                year = formatQuarter(item.date)
            }
            let yearValue

            if (values === 'rub') yearValue = item.value == null ? '—' : parameterKey === 'KeyStats' ? item.value : item.value / 1000000000;
            if (values === 'changeYoy') yearValue = item.change_YoY == null ? '—' : parameterKey === 'KeyStats' ? item.change_YoY.toFixed(2) : item.change_YoY;
            if (values === 'change') yearValue = item.change == null ? '—' : parameterKey === 'KeyStats' ? item.change.toFixed(2) : item.change

            // Если для этого ключа еще нет строки, создаем её
            if (!dataMap[titleKey]) {
                dataMap[titleKey] = {
                    key: titleKey, // Уникальный ключ для dataRow
                    name: (
                        <span
                            className={`${styles.rowTitle} ${selectedRows && !selectedRows.includes(titleKey) && styles.rowTitlePadding}`}>
                            {selectedRows && selectedRows.includes(titleKey) && checkmarkImg}
                            {indicatorValue(titleKey)}
                        </span>
                    )
                };
            }

            const compareValue = dataTest && dataTest[parameterKey]?.some(testItem => testItem.parameter === titleKey && new Date(testItem.date).getFullYear() === year) && (dataTest[parameterKey].find(testItem => testItem.parameter === titleKey && new Date(testItem.date).getFullYear() === year)?.value == null ? '—' : parameterKey === 'KeyStats' ? dataTest[parameterKey].find(testItem => testItem.parameter === titleKey && new Date(testItem.date).getFullYear() === year)?.value : dataTest[parameterKey].find(testItem => testItem.parameter === titleKey && new Date(testItem.date).getFullYear() === year)?.value / 1000000000)

            // Если колонка для этого года еще не создана, создаем её
            if (!dataMap[titleKey][year]) {
                dataMap[titleKey][year] = (
                    <div className={`${styles.col} ${yearValue < 0 && styles.negativeValue}`}>
                        <span>
                            {transformYearValue(yearValue, titleKey, parameterKey)}
                        </span>
                        {dataTest && dataTest[parameterKey]?.some(testItem => testItem.parameter === titleKey && new Date(testItem.date).getFullYear() === year) && (
                            transformYearValue(compareValue, titleKey, parameterKey)
                        )}
                    </div>
                );
            }
        });

        // Затем добавляем обработанные данные для текущего parameterKey
        result.push(...Object.values(dataMap));
    });

    return result;
};

// Функция формирования столбцов для таблицы прогнозов
export const generateDataForPredict = (value, selectedRows) => {
   /* const checkmarkImg = <img src={checkmarkIcon} alt="checkmark" width={24}/>;
    const result = []; // Итоговый массив для результатов

    // Проходим по каждому параметру в объекте dataSource (например, CashFlowFromOperations, Supplemental)
    Object.keys(value).forEach(parameterKey => {
        const dataMap = {}; // Для хранения уникальных строк с ключом rowTitle

        const dataList = value[parameterKey]; // Получаем список данных для текущего параметра
        const filteredArray = dataList.filter(item => item.parameter !== 'totalAssets' && item.parameter !== 'commonStockSharesOutstanding'
            && item.parameter !== 'freecashflowpershare' && item.parameter !== 'revenuepershare' && item.parameter !== 'predictions_2025' && item.parameter !== 'predictions_2024'
            && item.parameter !== 'payout' // ВРЕМЕННО, ПОКА НЕ ПОЯВИТСЯ БЛОК ДИВИДЕНДЫ
        );

        if (period === 'Q') filteredArray.sort((a, b) => new Date(a.date) - new Date(b.date))

        filteredArray.forEach(item => {
            const titleKey = item.parameter; // Используем параметр как уникальный ключ для строки
            let year
            if (period === 'Y') {
                year = item.date === 'ttm' ? 'TTM' : item.date === 'average5' ? 'Средняя за 5 лет' : item.date === 'average10' ? 'Средняя за 10 лет' : new Date(item.date).getFullYear();
            } else {
                year = formatQuarter(item.date)
            }
            let yearValue

            if (values === 'rub') yearValue = item.value == null ? '—' : parameterKey === 'KeyStats' ? item.value : item.value / 1000000000;
            if (values === 'changeYoy') yearValue = item.change_YoY == null ? '—' : parameterKey === 'KeyStats' ? item.change_YoY.toFixed(2) : item.change_YoY;
            if (values === 'change') yearValue = item.change == null ? '—' : parameterKey === 'KeyStats' ? item.change.toFixed(2) : item.change

            // Если для этого ключа еще нет строки, создаем её
            if (!dataMap[titleKey]) {
                dataMap[titleKey] = {
                    key: titleKey, // Уникальный ключ для dataRow
                    name: (
                        <span
                            className={`${styles.rowTitle} ${selectedRows && !selectedRows.includes(titleKey) && styles.rowTitlePadding}`}>
                            {selectedRows && selectedRows.includes(titleKey) && checkmarkImg}
                            {indicatorValue(titleKey)}
                        </span>
                    )
                };
            }

            const compareValue = dataTest && dataTest[parameterKey]?.some(testItem => testItem.parameter === titleKey && new Date(testItem.date).getFullYear() === year) && (dataTest[parameterKey].find(testItem => testItem.parameter === titleKey && new Date(testItem.date).getFullYear() === year)?.value == null ? '—' : parameterKey === 'KeyStats' ? dataTest[parameterKey].find(testItem => testItem.parameter === titleKey && new Date(testItem.date).getFullYear() === year)?.value : dataTest[parameterKey].find(testItem => testItem.parameter === titleKey && new Date(testItem.date).getFullYear() === year)?.value / 1000000000)

            // Если колонка для этого года еще не создана, создаем её
            if (!dataMap[titleKey][year]) {
                dataMap[titleKey][year] = (
                    <div className={`${styles.col} ${yearValue < 0 && styles.negativeValue}`}>
                        <span>
                            {transformYearValue(yearValue, titleKey, parameterKey)}
                        </span>
                        {dataTest && dataTest[parameterKey]?.some(testItem => testItem.parameter === titleKey && new Date(testItem.date).getFullYear() === year) && (
                            transformYearValue(compareValue, titleKey, parameterKey)
                        )}
                    </div>
                );
            }
        });

        // Затем добавляем обработанные данные для текущего parameterKey
        result.push(...Object.values(dataMap));
    });

    return result;*/
};

// Функция для преобразования данных TTM для последующего добавления в объект фин. данных
export function transformObject(obj, type) {
    const result = {};

    Object.keys(obj).forEach(section => {
        result[section] = [];

        Object.keys(obj[section]).forEach(key => {
            const value = obj[section][key];
            const subBlock = key.substring(0, key.lastIndexOf('_')); // Обрезаем название ключа до последнего '_'
            const parameter = key.substring(key.indexOf('_') + 1); // Обрезаем всё до первого '_'

            result[section].push({
                change: null,
                change_YoY: null,
                date: type,
                parameter: parameter,
                type: "Y",
                value: value,
                subBlock: subBlock
            });
        });
    });

    return result;
}

// Функция добавляющая значения ttm в фин.показатели
export function fillObjectFromArr(obj, arrTTM, arrAverageFiveYears, arrAverageTenYears) {
    const updatedObj = {...obj};

    arrTTM.forEach(item => {
        const key = item.subBlock;
        if (updatedObj.hasOwnProperty(key)) {
            updatedObj[key] = [...updatedObj[key], item];
        }
    });

    arrAverageFiveYears.forEach(item => {
        const key = item.subBlock;
        if (updatedObj.hasOwnProperty(key)) {
            updatedObj[key] = [...updatedObj[key], item];
        }
    });

    arrAverageTenYears.forEach(item => {
        const key = item.subBlock;
        if (updatedObj.hasOwnProperty(key)) {
            updatedObj[key] = [...updatedObj[key], item];
        }
    });

    return updatedObj;
}

