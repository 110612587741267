import React, {useEffect, useState} from 'react';
import {ConfigProvider, Table} from 'antd';
import {generateDataForPredict, generateDataFromValue} from "../utilsForFinanceTab";
import styles from './finance-table.module.css'
import {useResize} from "../../../../../../services/hooks/useResize";
import {Tooltip} from "recharts";
import {InfoCircleOutlined} from "@ant-design/icons";

export const FinanceTable = ({selectedRows, setSelectedRows, dataBlock, dataTest, keyBlock, period, values}) => {
    let tableData
    if(keyBlock !== 'predictions') tableData = generateDataFromValue(dataBlock, selectedRows, dataTest, period, values);
    if(keyBlock === 'predictions') tableData = generateDataForPredict(dataBlock, selectedRows);

    const [isShowTooltip, setIsShowTooltip] = useState(false)

    const screenWidth = useResize()

    const getUniqueKeys = (arr) => {
        // Используем Set для хранения уникальных значений
        const uniqueKeys = new Set();

        arr.forEach(item => {
            Object.keys(item).forEach(key => {
                // Исключаем ключи 'key' и 'name'
                if (key !== 'key' && key !== 'name') {
                    uniqueKeys.add(key);
                }
            });
        });

        // Возвращаем массив из уникальных ключей
        return Array.from(uniqueKeys);
    }

    const handleRowClick = (record) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(record.key)) {
                return prevSelectedRows.filter(key => key !== record.key);
            } else {
                return [...prevSelectedRows, record.key];
            }
        });
    };

    const rowClassName = (record) => {
        let className = styles.tableRow;

        if (selectedRows && selectedRows.includes(record.key)) {
            className += ` ${styles.selectedRow}`;
        }
        return className;
    };

    const transformTableData = (data) => {
        if (keyBlock === 'incomeStatement') {
            const dataCopy = [...data]
            const order = [
                "totalRevenue",
                "costOfRevenue",
                "grossProfit",
                "grossMargin",
                "totalOperatingExpenses",
                "researchDevelopment",
                "sellingGeneralAdministrative",
                "operatingIncome",
                "operatingMargin",
                "ebitda",
                "oibda",
                "ebitdaMargin",
                "oibdaMargin",
                "interestExpense",
                "interestIncome",
                "incomeTaxExpense",
                "netIncome",
                "netMargin",
            ];

            return dataCopy.sort((a, b) => {
                return order.indexOf(a.key) - order.indexOf(b.key);
            });
        }

        if (keyBlock === 'balanceSheet') {
            const dataCopy = [...data]
            const order = [
                "liabilitiesTotal",
                "totalStockholderEquity",
                "cashAndEquivalents",
                "totalDebt",
                "netDebt",
                "capitalization",
                "ev"
            ];

            return dataCopy.sort((a, b) => {
                return order.indexOf(a.key) - order.indexOf(b.key);
            });
        }

        if (keyBlock === 'cashFlow') {
            const dataCopy = [...data]
            const order = [
                "totalCashFromOperatingActivities",
                "capitalExpenditures",
                "freeCashFlow",
            ];

            return dataCopy.sort((a, b) => {
                return order.indexOf(a.key) - order.indexOf(b.key);
            });
        }

        if (keyBlock === 'predictions') {
            const dataCopy = [...data]
            const order = [
                "netIncome",
                "pe",
                "ebitda",
                "evtoebitda",
                "netdebttoebitda",
                // "Див доходность"
            ];

            return dataCopy.sort((a, b) => {
                return order.indexOf(a.key) - order.indexOf(b.key);
            });
        }

        return data
    }


    const getColumns = () => {
        const columns = [
            {
                title: '',
                width: screenWidth > 500 ? 250 : 150,
                dataIndex: 'name',
                key: 'name',
                fixed: screenWidth > 500 ? 'left' : false,
            }
        ]

        const arr = getUniqueKeys(tableData)

        arr.forEach(title => {
            columns.push({
                title: <div className={styles.titleCell}>
                    {title === 'averages_5y' ? 'Средняя за 5 лет' : title === 'averages_10' ? 'Средняя за 10 лет' : title} {title === 'TTM' &&
                    <InfoCircleOutlined className={styles.icon}
                                        onMouseEnter={() => setIsShowTooltip(true)}
                                        onMouseLeave={() => setIsShowTooltip(false)}
                    />}
                </div>,
                width: 100,
                dataIndex: title.toString(),
                key: title.toString(),
                align: 'center',
            });
        });

        return columns
    }

    return (
        <div className={styles.tableContainer}>
            {isShowTooltip &&
                <span className={styles.tooltipText}>TTM - Trailing Twelve Months - значения показателей, рассчитанные за 12 месяцев на поквартальной основе, с учетом последних доступных данных за квартал.</span>}
            <ConfigProvider
                theme={{
                    components: {
                        Table: {
                            borderColor: '#DBDBDB',
                            fontFamily: 'Montserrat',
                        }
                    }
                }}
            >
                <Table dataSource={transformTableData(tableData)} pagination={false} className={styles.table}
                       rowClassName={rowClassName}
                       onRow={(record) => ({
                           onClick: () => handleRowClick(record),
                       })}
                       scroll={{x: 940}}
                       columns={getColumns()}
                >
                </Table>
            </ConfigProvider>
        </div>)
}