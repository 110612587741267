import React, {useEffect, useState} from "react";
import loop from "../../../../../../images/loop.svg";
import cross from "../../../../../../images/cross-search.svg";
import {SearchListItem} from "../../screener-main/search-list-item/search-list-item";
import {getSearchList} from "../../../../../../utils/api";
import styles from "./finance-search-input.module.css";

export const FinanceSearchInput = ({keyBlock, getFinanceDataForBlock, selectedCompany, setSelectedCompany}) => {
    const [searchValue, setSearchValue] = useState(selectedCompany || '')
    const [isSearchFocusActive, setIsSearchFocusActive] = useState(false)
    const [isShowCrossIcon, setIsShowCrossIcon] = useState(false)
    const [searchResults, setSearchResults] = useState([])
    const [isShowSearchList, setIsShowSearchList] = useState(false)

    const onChangeHandler = (e) => {
        setSearchValue(e.target.value)
    }

    const onFocusSearchHandler = () => {
        setIsSearchFocusActive(true)
        setIsShowSearchList(true)
        setIsShowCrossIcon(true)
    }

    const onBlurSearchHandler = () => {
        setIsSearchFocusActive(false)
        setIsShowSearchList(false)
        setIsShowCrossIcon(false)
    }

    const clearSearchInput = () => {
        setSearchValue('')
    }

    const onClickForCompare = (ticker, keyBlock) => {
        setIsSearchFocusActive(false)
        setIsShowSearchList(false)
        setIsShowCrossIcon(false)
        getFinanceDataForBlock(ticker, keyBlock)
    }

    const resetClick = () => {
        setSelectedCompany({})
    }

    useEffect(() => {
        if (searchValue !== '') {
            getSearchList(searchValue).then(async (res) => {
                if (res.ok) {
                    const data = await res.json()
                    setSearchResults(data)
                } else {
                    console.log(res)
                }
            })
        } else {
            setSearchResults([])
        }
    }, [searchValue]);

    return (
        <div className={`${styles.searchInput} ${isSearchFocusActive && styles.fullWidthSearchInput}`}>
            <input className={styles.search} type="text" onChange={onChangeHandler} value={searchValue}
                   placeholder={'Поиск'} onFocus={onFocusSearchHandler}
                   onBlur={onBlurSearchHandler}/>
            <img src={loop} alt="loop icon" className={styles.loopIcon}/>

            {isShowCrossIcon && searchValue && <img src={cross}
                                                    alt="cross icon"
                                                    className={styles.crossIcon}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    onClick={clearSearchInput}/>}

            {searchValue !== '' && !isSearchFocusActive &&
            <button className={styles.resetButton} onClick={resetClick}>Сбросить</button>
            }

            {searchResults.length > 0 && isShowSearchList && <div className={styles.searchList}>
                {searchResults.map(item => {
                    return <SearchListItem key={`${item.ticker}-${Math.random()}`} title={item.name}
                                           ticker={item.ticker} sector={item.sector[0].name} isCompare={true}
                                           onClickForCompare={onClickForCompare} keyBlock={keyBlock}/>
                })}

            </div>}
        </div>
    );
};